<template>

    <div class="cr-match-detail-section" v-if="match">
        <div class="cr-match-detail-top">
            <div class="cr-match-heading">
                <h1 v-if="match.tournament.name"><span class="status" v-if="match.status">{{match.status}}</span> {{match.tournament.name}}</h1>
                <strong v-if="match.matchno" class="cr-session">{{match.matchno}}</strong>
            </div>
            <div class="cr-match-body">
                <div class="cr-match-team">
                    <div class="icon-logo">
                        <img :src="$storageURL+`images/cricket/round/${(match.team1.id)}.webp`" :onerror="$teamDefaultLogo()" alt="logo" width="104" height="60">
                    </div>
                    <div class="descrip">
                        <strong class="team" :class="{'orange-text': (match.active_team == match.team1.code)}" v-if="match.team1">{{match.team1.name}}</strong>
                        <span class="over-inf">{{match.ht_score}}</span>
                        <template v-for="inn in match.innings">
                            <span class="over-inf" v-if="inn.team_id == match.team1.id && inn.order == 1 && match.innings.length < 3">
                                {{ '('+inn.overs+' Ov)' }}
                            </span>
                            <span class="over-inf" v-if="inn.team_id == match.team1.id && inn.order == 1 && inn.number == 1 && (match.innings.length == 3 && match.innings[2].team_id != inn.team_id)">
                                {{ '('+inn.overs+' Ov)' }}
                            </span>
                            <span class="over-inf" v-if="inn.team_id == match.team1.id && inn.order == 2 && match.innings.length < 3">
                                {{ '('+inn.overs+' Ov)' }}
                            </span>
                            <span class="over-inf" v-if="inn.team_id == match.team1.id && inn.order == 2 && inn.number == 1 && (match.innings.length == 3 && match.innings[2].team_id != inn.team_id)">
                                {{ '('+inn.overs+' Ov)' }}
                            </span>
                            <span class="over-inf" v-if="inn.team_id == match.team1.id && inn.order == 3 && match.innings.length >= 3">
                                {{ '('+inn.overs+' Ov)' }}
                            </span>
                            <span class="over-inf" v-if="inn.team_id == match.team1.id && inn.order == 4 && match.innings.length >= 3">
                                {{ '('+inn.overs+' Ov)' }}
                            </span>
                        </template>

                        <!-- <span class="over-inf" v-else-if="match.is_test_match==false && match.innings[1] && match.team1_id == match.innings[1].team_id">{{match.innings[1].score}}/{{match.innings[1].wickets}} ({{match.innings[1].overs}} Ov)</span>
                        <span class="over-inf" v-else-if="match.is_test_match==true && match.innings.length==1 && match.innings[0].team_id==match.team1_id">{{match.innings[0].score}}/{{match.innings[0].wickets}} ({{match.innings[0]['overs']}} Ov)</span>
                        <span class="over-inf" v-else-if="match.is_test_match==true && match.innings.length==2 && match.innings[1].team_id==match.team1_id">{{match.innings[1].score}}/{{match.innings[1].wickets}} ({{match.innings[1]['overs']}} Ov)</span>
                        <span class="over-inf" v-else-if="match.is_test_match==true && match.innings.length==3 && match.innings[2].team_id==match.innings[0].team_id">{{match.innings[0].score}}/{{match.innings[0].wickets}} &nbsp;&amp; {{match.innings[2].score}}/{{match.innings[2].wickets}} ({{match.innings[2]['overs']}} Ov)</span>
                        <span class="over-inf" v-else-if="match.is_test_match==true && match.innings.length==3 && match.innings[2].team_id==match.innings[1].team_id">{{match.innings[1].score}}/{{match.innings[1].wickets}} &nbsp;&amp; {{match.innings[2].score}}/{{match.innings[2].wickets}} ({{match.innings[2]['overs']}} Ov)</span>
                        <span class="over-inf" v-else-if="match.is_test_match==true && match.innings.length==4 && match.innings[3].team_id==match.innings[1].team_id">{{match.innings[1].score}}/{{match.innings[1].wickets}} &nbsp;&amp; {{match.innings[3].score}}/{{match.innings[3].wickets}} ({{match.innings[3]['overs']}} Ov)</span>
                        <span class="over-inf" v-else-if="match.is_test_match==true && match.innings.length==4 && match.innings[3].team_id==match.innings[0].team_id">{{match.innings[0].score}}/{{match.innings[0].wickets}} &nbsp;&amp; {{match.innings[3].score}}/{{match.innings[3].wickets}} ({{match.innings[3]['overs']}} Ov)</span> -->
                    </div>
                </div>
                <div class="cr-match-status" v-if="match.status  == 'Not Started'">
                    <div class="align" v-if="left">
                        <span class="timer"> <span>{{left.days}}</span> : <span>{{left.hours}}</span> : <span>{{left.minutes}}</span> : <span>{{left.seconds}}</span></span>
                    </div>
                </div>
                <div class="cr-match-status" v-else>
                    <div class="align" v-if="match.innings.length>0 && match.innings">
                        <span class="crr">CRR: {{match.innings[match.innings.length-1].runrate}}</span>
                    </div>
                </div>
                <div class="cr-match-team right">
                    <div class="icon-logo">
                        <img :src="$storageURL+`images/cricket/round/${match.team2.id}.webp`" :onerror="$teamDefaultLogo()" alt="logo" width="104" height="60">
                    </div>
                    <div class="descrip">
                        <strong class="team" :class="{'orange-text': (match.active_team == match.team2.code)}" v-if="match.team2">{{match.team2.name}}</strong>
                        <span class="over-inf">{{match.at_score}}</span>
                        <template v-for="inn in match.innings">
                            <span class="over-inf" v-if="inn.team_id == match.team2.id && inn.order == 1 && match.innings.length < 3">
                            {{ ' ('+inn.overs+' Ov)' }}
                            </span>
                            <span class="over-inf" v-if="inn.team_id == match.team2.id && inn.order == 1 && inn.number == 1 && (match.innings.length == 3 && match.innings[2].team_id != inn.team_id)">
                                {{ '('+inn.overs+' Ov)' }}
                            </span>
                            <span class="over-inf" v-if="inn.team_id == match.team2.id && inn.order == 2 && match.innings.length < 3">
                                {{ ' ('+inn.overs+' Ov)' }}
                            </span>
                            <span class="over-inf" v-if="inn.team_id == match.team2.id && inn.order == 2 && inn.number == 1 && (match.innings.length == 3 && match.innings[2].team_id != inn.team_id)">
                                {{ '('+inn.overs+' Ov)' }}
                            </span>
                            <span class="over-inf" v-if="inn.team_id == match.team2.id && inn.order == 3 && match.innings.length >= 3">
                                {{ ' ('+inn.overs+' Ov)' }}
                            </span>
                            <span class="over-inf" v-if="inn.team_id == match.team2.id && inn.order == 4 && match.innings.length >= 3">
                                {{ ' ('+inn.overs+' Ov)' }}
                            </span>
                        </template>
    <!--
                        <span class="over-inf" v-if="match.is_test_match==false && match.innings[0] && match.team2_id == match.innings[0].team_id">{{match.innings[0].score}}/{{match.innings[0].wickets}} ({{match.innings[0].overs}} Ov)</span>
                        <span class="over-inf" v-else-if="match.is_test_match==false && match.innings[1] && match.team2_id == match.innings[1].team_id">{{match.innings[1].score}}/{{match.innings[1].wickets}} ({{match.innings[1].overs}} Ov)</span>
                        <span class="over-inf" v-else-if="match.is_test_match==true && match.innings.length==1 && match.innings[0].team_id==match.team2_id">{{match.innings[0]['score']}}/{{match.innings[0]['wickets']}} ({{match.innings[0]['overs']}} Ov)</span>
                        <span class="over-inf" v-else-if="match.is_test_match==true && match.innings.length==2 && match.innings[0].team_id==match.team2_id">{{match.innings[0]['score']}}/{{match.innings[0]['wickets']}} ({{match.innings[0]['overs']}} Ov)</span>
                        <span class="over-inf" v-else-if="match.is_test_match==true && match.innings.length=='3' && match.innings[0]['team_id']==match.innings[2]['team_id']">{{match.innings[1]['score']}}/{{match.innings[1]['wickets']}} ({{match.innings[1]['overs']}} Ov)</span>
                        <span class="over-inf" v-else-if="match.is_test_match==true && match.innings.length=='3' && match.innings[1]['team_id']==match.innings[2]['team_id']">{{match.innings[0]['score']}}/{{match.innings[0]['wickets']}} ({{match.innings[0]['overs']}} Ov)</span>
                        <span class="over-inf" v-else-if="match.is_test_match==true && match.innings.length=='4' && match.innings[1]['team_id']==match.innings[3]['team_id']">{{match.innings[0]['score']}}/{{match.innings[0]['wickets']}} &nbsp;&amp; {{match.innings[2]['score']}}/{{match.innings[2]['wickets']}} ({{match.innings[2]['overs']}} Ov)</span>
                        <span class="over-inf" v-else-if="match.is_test_match==true && match.innings.length=='4' && match.innings[0]['team_id']==match.innings[3]['team_id']">{{match.innings[1]['score']}}/{{match.innings[1]['wickets']}} &nbsp;&amp; {{match.innings[2]['score']}}/{{match.innings[2]['wickets']}} ({{match.innings[2]['overs']}} Ov)</span> -->

                    </div>
                </div>
            </div>

            <div class="cr-match-foot" v-if="match.comment">
                <p>{{match.comment}}</p>
            </div>
        </div>
        <div v-if="match.status =='Not Started'" class="header_for_upcoming_match"></div>
        <div v-if="match.status!='Not Started'" :class="(match.old_widget == 0) ? 'clc_widget_crctGround_dtl animation-live' : 'clc_widget_dtl_picth finish-status'">
            <!--widget code start here-->
            <div id="cricket-widget">
                <div class="circket_wrapper">
                    <div class="main_ground_img w-100" v-if="match.old_widget == 1">
                        <img :src="require('@/assets/widget/images/new_ground.png?v=2')" alt="" width="1232" height="474" fetchpriority="high">
                        <span class="players_left" v-if="match.format=='test'">
                        <img :src="require('@/assets/widget/images/team_kits/Bowler.png')" alt="Bowler" width="209" height="270" />
                        </span>
                        <span class="players_left" v-else>
                            <img :src="`https://bw.sixlogics.com/cricket/assets/images/team_kits/${match.team1_id}_Bowler.png`" @error="BowerImageUrl" alt="Bowler" width="209" height="270" />
                        </span>
                        <span class="players_right" v-if="match.format=='test'">
                        <img :src="require('@/assets/widget/images/team_kits/Batsman.png')" alt="batsman" width="295" height="205">
                        </span>
                        <span class="players_right" v-else>
                        <img :src="`https://bw.sixlogics.com/cricket/assets/images/team_kits/${match.team2_id}_Batsman.png`" @error="BatsmanImageUrl" alt="batsman" width="295" height="205">
                        </span>
                        <ul class="info-ul">
                            <li>
                                <span class="d-block" v-if="match.activeBowler && match.activeBowler.player">{{match.activeBowler.player.name}}</span>
                                <span class="d-block" v-if="match.activeBowler && match.activeBowler.player">Ov: {{match.activeBowler.overs}} - R: {{match.activeBowler.score}} - W: {{match.activeBowler.wickets}}</span>
                            </li>
                            <li>
                                <span class="d-block" v-if="match.activeBatsman1 && match.activeBatsman1.player">{{match.activeBatsman1.player.name}} {{match.activeBatsman1.score}} ({{match.activeBatsman1.balls}})</span>
                                    <span class="d-block" v-if="match.activeBatsman2 && match.activeBatsman2.player">{{match.activeBatsman2.player.name}} {{match.activeBatsman2.score}} ({{match.activeBatsman2.balls}})</span>
                            </li>
                        </ul>
                    </div>
                    <div class="live-animation-match" v-else>
                        <div class="players-wrpa" >
                            <div id="wagon">
                                <div class="App">
                                    <div class="balltrack" id="ball_track">
                                        <img :src="require('@/assets/widget/images/ball.png')" id="ball" style="display: none;" />
                                        <img :src="require('@/assets/widget/images/ball_shadow.png')" id="ball_shadowUrl" style="display: none;" />
                                        <div class="main_box" :style="{ backgroundImage: 'url(' + require('@/assets/widget/images/pitch.png') + ')' }">
                                            <div :class="match.batsman_left_flag" v-if="match.activeInning && match.activeInning.team_id && !match.is_test_match"><img :src="`https://bw.sixlogics.com/cricket/assets/images/team_kits_new/${match.activeInning.team_id}_batsman.png`" @error="BatsmanwidgetImageUrl" alt="player image" width="89" height="138" /></div>
                                            <div :class="match.batsman_left_flag" v-else><img :src="require('@/assets/widget/images/team_kits/default_player.png')" alt="player image" width="89" height="138" /></div>
                                            <canvas id="canvas" ref="canvas_ball_track" width="507" height="703" style="width: 100%;"></canvas>
                                        </div>
                                    </div>
                                    <div class="wagon">
                                        <ul class="wagon-info-list">
                                            <li v-if="match.activeInning.overs">Over {{parseFloat(match.activeInning.overs).toFixed(1)}}</li>
                                            <template v-if="match.latest_commentaries && match.latest_commentaries.length > 0">
                                                <li v-if="match.latest_commentaries && match.latest_commentaries!=''"> {{match.latest_commentaries[0].score}}</li>
                                                <li v-if="match.latest_commentaries && match.latest_commentaries!='' && match.latest_commentaries[0].position!='0' && match.activeBatsman1.player.batting_style=='Right-hand bat' && short_type(match.latest_commentaries[0].position)"> {{short_type(match.latest_commentaries[0].position)}}</li>
                                                <li v-if="match.latest_commentaries && match.latest_commentaries!='' && match.latest_commentaries[0].position!='0' && match.activeBatsman1.player.batting_style=='Left-hand bat' && left_short_type(match.latest_commentaries[0].position)"> {{left_short_type(match.latest_commentaries[0].position)}}</li>
                                            </template>
                                        </ul>
                                        <div class="wagon_wheel_wrap">
                                            <div class="wagon_wheel_left">
                                                <canvas id="latest_comment_wagonwheel" ref="latest_comment_wagonwheel_ref" style="border-radius: inherit;"></canvas>
                                                <div class="animation_wrap animated_action" v-if="popup_value!==''">
                                                    <img :src="require(`@/assets/widget/images/popups/${popup_value}.png`)"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cr-match-detail-rgt">
                            <div class="det-rgt-box">
                                <strong class="cr-title">Batsman</strong>
                                <ul class="rgt-box-ul" v-if="match.activeBatsman1 && match.activeBatsman1.player">
                                    <li>
                                        <span class="cr-ul-title" :class="[{'orange-text' : match.activeBatsman1.active}] ">{{match.activeBatsman1.player.name}}</span>
                                        <span class="cr-ul-scrore"><span class="ornge-text">{{match.activeBatsman1.score}}</span> ({{match.activeBatsman1.balls}})</span>
                                    </li>
                                    <li v-if="match.activeBatsman2 && match.activeBatsman2.player">
                                        <span class="cr-ul-title" :class="[{'orange-text' : match.activeBatsman2.active}] ">{{match.activeBatsman2.player.name}}</span>
                                        <span class="cr-ul-scrore"><span class="ornge-text">{{match.activeBatsman2.score}}</span> ({{match.activeBatsman2.balls}})</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="det-rgt-box">
                                <strong class="cr-title">Bowler</strong>
                                <ul class="rgt-box-ul blr">
                                    <li v-if="match.activeBowler && match.activeBowler.player">
                                        <span class="cr-ul-title">{{match.activeBowler.player.name}}</span>
                                        <span class="cr-ul-scrore">Ov: {{match.activeBowler.overs}} - R: {{match.activeBowler.score}} - W: {{match.activeBowler.wickets}}</span>
                                    </li>
                                </ul>
                                <ul class="recent_over_list" v-if="match.activeBowler" v-html="match.currentOver_html">
                                </ul>
                            </div>
                            <div class="det-rgt-box">
                                <ul class="rgt-box-ul">
                                    <li v-if="typeof match.requiredScoreBalls!=='undefined' && match.requiredScoreBalls.length=='3'">
                                        <span class="cr-ul-title">{{match.requiredScoreBalls[0][0][0]}}:</span>
                                        <span class="cr-ul-scrore">{{match.requiredScoreBalls[0][1][0]}}</span>
                                    </li>
                                    <li v-if="typeof match.requiredScoreBalls!=='undefined' && match.requiredScoreBalls.length=='3'">
                                        <span class="cr-ul-title">{{match.requiredScoreBalls[1][0][0]}}:</span>
                                        <span class="cr-ul-scrore">{{match.requiredScoreBalls[1][1][0]}}</span>
                                    </li>
                                    <li v-if="typeof match.requiredScoreBalls!=='undefined' && match.requiredScoreBalls.length=='1'">
                                        <span class="cr-ul-title">{{match.requiredScoreBalls[0][0][0]}}:</span>
                                        <span class="cr-ul-scrore">{{match.requiredScoreBalls[0][1][0]}}</span>
                                    </li>
                                    <li>
                                        <span class="cr-ul-title">Current RR:</span>
                                        <span class="cr-ul-scrore" v-if="match.innings.length>0 && match.innings">{{match.innings[match.innings.length-1].runrate}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!----model code start here-->

                </div>
            </div>
            <img style="display: none;" src="@/assets/widget/images/new_ground.png?v=2" loading="lazy" />
        </div>
    </div>

</template>

<script type="text/javascript">
import moment from 'moment';
import moment_timezone from 'moment-timezone';
import Vue from 'vue';
    export default {
        name: "cricket-widget",
        data() {
            return {
                match : null,
                canvas : null,
                countfirst :1,
                popup_value:'',
                new_ball_position:'',
                pre_commentry_id:0,
                left: null,
                ctx : "",
                timerIntv: null
            }
        },
       async mounted () {
           var _this = this;
           await this.getDetail(false);
           if (this.match.status == 'Not Started') {
               this.timerIntv = setInterval(() => {
                    this.remainingTime();
                    if (this.left.days == 0 && this.left.hours == 0 && this.left.minutes == 0 && this.left.seconds == 0) {
                        this.getDetail(false);
                    }
                }, 1000);
           } else {
               if (this.timerIntv) {
                   clearInterval(this.timerIntv);
               }
               setTimeout(() => {
                   this.getDetail(false);
               }, 500);
           }
           this.$root.$on('CricMatchUpdated', data => {
               if (data.match_id == this.match.id) {
                   _this.getDetail(true);
               }
           });
       },
        beforeDestroy() {
            this.$root.$off('CricMatchUpdated')
            clearInterval(this.timerIntv);
        },
        serverPrefetch() {
            return this.getDetail(false,'server');
        },
        methods: {
              BowerImageUrl(event)
              {
              event.target.src = "https://bw.sixlogics.com/cricket/assets/images/team_kits/Bowler.png"
              },
              BatsmanwidgetImageUrl(event)
              {
                  event.target.src = "https://bw.sixlogics.com/cricket/assets/images/team_kits_new/default_player.png"
              },
              BatsmanImageUrl(event)
              {
              event.target.src = "https://bw.sixlogics.com/cricket/assets/images/team_kits/Batsman.png"
              },
              remainingTime(p_type = 'api') {
                if(p_type == 'server'){
                  var a = moment().add(5, 'hours');
                } else {
                  var a = moment()
                }
                // var c = moment().add(2, 'hours');
                // console.log(c);
                var b = moment_timezone.utc(String(this.match.gmt_datetime)).tz(cookies.get('timezone'));
                // var diff = b.diff(a, 'seconds');
                var diff = 0;
                if(b != undefined){
                    diff = b.diff(a, 'seconds');
                }
                var current=moment().format('YYYY-MM-DD HH:mm:ss');
                var match_time= moment_timezone.utc(String(this.match.gmt_datetime)).tz(cookies.get('timezone'));
                if (current > match_time)
                {
                 this.left.days='00';
                 this.left.hours='00';
                 this.left.minutes='00';
                 this.left.seconds='00';
                  } else {

               diff = Math.abs(diff);

                var day = 86400;
                var hour = 3600;
                var minute = 60;

                var totalseconds = diff;
                if (this.left == null) {
                    this.left = {days:'',hours:'',minutes:'',seconds:''}
                }

                this.left.days = Math.floor(totalseconds / day);
                this.left.days = this.left.days > 9 ? this.left.days : '0'+this.left.days;
                this.left.hours = Math.floor((totalseconds - this.left.days * day)/hour);
                this.left.hours = this.left.hours > 9 ? this.left.hours : '0'+this.left.hours;
                this.left.minutes = Math.floor((totalseconds - this.left.days * day - this.left.hours * hour)/minute);
                this.left.minutes = this.left.minutes > 9 ? this.left.minutes : '0'+this.left.minutes;
                this.left.seconds = totalseconds - this.left.days * day - this.left.hours * hour - this.left.minutes * minute;
                this.left.seconds = this.left.seconds > 9 ? this.left.seconds : '0'+this.left.seconds;

                return this.left;

                  }

            },
              async getDetail(cachepurge,s_type)
              {
                await this.$axios
                    .get(this.$MATCH_COMMENTRY_API.replace(':match', this.$route.params.id),{
                      headers: cachepurge ? {'cachepurge': true} : {}
                    })
                    .then(response => {
                        this.match = response.data.result;
                        this.match.currentOver_c = [];
                        this.match.currentOver_d_s = [];
                        var html ='';
                        var count = 0;
                        if (this.match.status == 'Not Started') {
                            this.remainingTime(s_type);
                        }
                        Object.entries(this.match.currentOver).forEach(([key, user]) => {
                            let cls ='';
                            let inner_html = '';
                            if (user.d_score == "w" || user.d_score == "W") {
                                    cls = 'wicket';
                                    if(key==0){
                                        if (user.score == "Catch OUT") {
                                            this.popup_value = 'catchout';
                                        } else if (user.score == "Clean BOWLED") {
                                            this.popup_value = 'cleanbowled';
                                        } else if (user.score == "Clean Bowled") {
                                            this.popup_value = 'cleanbowled';
                                        } else if (user.score == "LBW OUT") {
                                            this.popup_value = 'lbwout';
                                        } else if (user.score == "RUN OUT") {
                                            this.popup_value = 'runout';
                                        } else if (user.score == "Stump OUT") {
                                            this.popup_value = 'stumpout';
                                        } else if (user.score == "OUT") {
                                            this.popup_value = 'cleanbowled';
                                        }
                                    }
                                }
                                else if(user.d_score =="6"){
                                    cls = 'six';
                                     key==0 ? this.popup_value='six' :'';
                                }
                                else if(user.d_score =="4"){
                                    cls = 'four';
                                    key==0 ? this.popup_value='four' :'';
                                }
                                else if (user.d_score == 'nb') {
                                    cls = 'no-ball radius-10';
                                    count--;
                                     if(key==0){this.popup_value=''}
                                } else if (user.d_score == 'wd1' || user.d_score == 'wd') {
                                    cls = 'wide';
                                    count--;
                                    if(key==0){this.popup_value=''}
                                } else {
                                    cls = 'no-class';
                                      if(key==0){this.popup_value=''}
                                }
                            inner_html = '<li class="'+cls+'"><span>'+user.d_score+'</span></li>';
                            html = inner_html+''+html;
                            count++;
                        });
                        while(count<6){
                            html +='<li><span></span></li>';
                            count++;
                        }
                        this.match.currentOver_html = html;
                        if(this.countfirst !==1 && this.match.latest_commentaries && this.match.latest_commentaries.length >0)
                        {
                            let latest_commentaries = this.match.latest_commentaries;
                            let cur_commentry_id = '';
                          if(this.match.latest_commentaries[0].ball_postions && this.match.latest_commentaries[0].ball_postions.length=='3' && this.match.latest_commentaries[0].ball_postions[2]!='')
                          {
                            let postion1=this.match.latest_commentaries[0].ball_postions[0].split('_');
                            let postion2=this.match.latest_commentaries[0].ball_postions[1].split('_');
                            let postion3=this.match.latest_commentaries[0].ball_postions[2].split('_');
                            var self = this;
                            if (typeof latest_commentaries[0].id !=='undefined')
                            {
                                cur_commentry_id = latest_commentaries[0].id;
                            }
                            if(this.pre_commentry_id==0)
                            {
                              this.popup_value='';
                              this.pre_commentry_id=cur_commentry_id;
                              this.wagon_wheel(latest_commentaries, 'latest_comment_wagonwheel', true);
                                var duration=1;
                               setTimeout(function () {
                                   self.ballTrackFunction(postion1[0], postion1[1], postion2[0], postion2[1], postion3[0], postion3[1], duration);
                                }, 1)
                            }
                            else if(cur_commentry_id != this.pre_commentry_id)
                            {
                                this.pre_commentry_id=cur_commentry_id;
                               this.wagon_wheel(latest_commentaries, 'latest_comment_wagonwheel', true);
                                  var duration=1500;
                                  setTimeout(function () {
                                      self.ballTrackFunction(postion1[0], postion1[1], postion2[0], postion2[1], postion3[0], postion3[1], duration);
                                  }, 1000)
                                  if(this.popup_value!='')
                                        {
                                           setTimeout(function () {
                                            this.popup_value='';
                                             }, 3000)
                                        }
                                       }else
                                    {
                                      this.popup_value='';
                                    }
                          }
                          else
                              {
                                if (typeof latest_commentaries[0].id !== 'undefined')
                                    {
                                        cur_commentry_id = latest_commentaries[0].id;
                                    }
                                     if (this.pre_commentry_id == 0)
                                    {
                                        this.popup_value='';
                                        this.pre_commentry_id = cur_commentry_id;
                                    }
                                     else if (cur_commentry_id != this.pre_commentry_id)
                                    {
                                       this.pre_commentry_id = cur_commentry_id;
                                        if(this.popup_value!='')
                                        {
                                           setTimeout(function () {
                                            this.popup_value='';
                                             }, 3000)
                                        }

                                    }
                                    else
                                    {
                                       this.popup_value='';
                                    }
                              }
                        }
                         if(this.match.status=='Finished'){
                               this.popup_value='';
                            }
                        if(this.countfirst ==1){
                            this.popup_value='';
                            this.countfirst++;
                        }
                    })
                    .catch(error => console.log(error));
            },
 wagon_wheel(latest_commentaries, id="latest_comment_wagonwheel", single_ball=null) {

                var canvas = document.getElementById(id);
                if(typeof canvas !== 'undefined' && canvas !== null){
                    var ctx = canvas.getContext('2d');
                    //ctx.clearRect(0, 0, canvas.width, canvas.height);
                    single_ball = single_ball || false;
                    if (latest_commentaries.length > 0 && latest_commentaries[0].position > 0) {
                        canvas = document.getElementById(id);
                        canvas.width = canvas.parentElement.clientWidth;
                        canvas.height = canvas.parentElement.clientHeight;
                        if (canvas.getContext) {
                            var startingPoint = {
                                xAxis: canvas.width / 2,
                                yAxis: canvas.height / 2 - 10,
                            };
                            ctx = canvas.getContext('2d');
                            //ctx.clearRect(0, 0, canvas.width, canvas.height);
                            var colors = {
                                1: '#bd08f3',
                                2: '#ffff00',
                                3: '#ffff00',
                                4: '#ebebeb',
                                6: '#ed2e12'
                            };
                            var lengths = {
                                1: canvas.width / 5,
                                2: canvas.width / 3,
                                3: canvas.width / 2.66,
                                4: canvas.width / 2.35,
                                6: canvas.width / 2.1
                            };
                            for (var i = 0; i < latest_commentaries.length; i++) {
                              var color = '#ffff00';
                                if (colors.hasOwnProperty(latest_commentaries[i].sc)) {
                                    color = colors[latest_commentaries[i].sc];
                                }
                                var len = 40;
                                if (lengths.hasOwnProperty(latest_commentaries[i].sc)) {
                                    len = lengths[latest_commentaries[i].sc];
                                }

                                if (id == "latest_comment_wagonwheel") {

                                    if (lengths.hasOwnProperty(latest_commentaries[i].sc)) {
                                       len = lengths[latest_commentaries[i].sc];
                                    } else {
                                        len = lengths['0'];
                                    }
                                }
                                var angle = latest_commentaries[i].position;
                                var x = Math.cos(angle * Math.PI / 180) * len;
                                var y = Math.sin(angle * Math.PI / 180) * len;
                                var targetPoint = {
                                    xAxis: startingPoint.xAxis + x,
                                    yAxis: startingPoint.yAxis + 10 + y,
                                };
                                ctx.beginPath();
                                ctx.moveTo(startingPoint.xAxis, startingPoint.yAxis);
                                ctx.lineWidth = 1.5;
                                ctx.lineTo(targetPoint.xAxis, targetPoint.yAxis);
                                ctx.strokeStyle = color;
                                ctx.stroke();

                                if (single_ball) {
                                    ctx.beginPath();
                                    ctx.arc(targetPoint.xAxis, targetPoint.yAxis, 3, 0, 2 * Math.PI, false);
                                    ctx.fillStyle = 'red';
                                    ctx.fill();
                                    ctx.strokeStyle = 'red';
                                    ctx.stroke();
                                }

                                ctx.closePath();
                            }
                        }
                    }
                }
            },
            ballTrackFunction(x1,y1,x2,y2,x3,y3,duration)
            {
                // var ctx = this.ctx;
                this.canvas = this.$refs.canvas_ball_track;
              var  ctx = this.canvas.getContext('2d')
               ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                var  canvasWidth = this.canvas.width;
                var  canvasHeight = this.canvas.height;
                var mid=  (x2);
                var  csX = mid/2;
                var  csY = y2*0.9;
                if(x2==x1 && x1>50)
                {
                    csX = mid*1.5;
                }
                else if(x2==x1 && x1<50)
                {
                    csX = mid/2;
                }
                else if (x2>x1 && x1<50)
                {
                    csX = mid*0.7;
                }
                else if(x1>50)
                {
                    csX = mid*1.2;
                }
                else if (x1<50)
                {
                    csX = mid/2;
                }
                else
                {
                    csX = mid;
                }
                var p1x = (x1 * canvasWidth) / 100;
                var p1y = (y1 * canvasHeight) / 100;
                var p2x = (x2 * canvasWidth) / 100;
                var p2y = (y2 * canvasHeight) / 100;
                var p3x = (x3 * canvasWidth) / 100;
                var p3y = (y3 * canvasHeight) / 100;
                var curveSplitX = (csX * canvasWidth) / 100;
                var curveSplitY = (csY * canvasHeight) / 100;
                localStorage.setItem('p1x',p1x);
                localStorage.setItem('p1y',p1y);
                localStorage.setItem('p2x',p2x);
                localStorage.setItem('p2y',p2y);
                localStorage.setItem('p3x',p3x);
                localStorage.setItem('p3y',p3y);
                localStorage.setItem('curveSplitX',curveSplitX);
                localStorage.setItem('curveSplitY',curveSplitY);
                this.animatePathDrawing(ctx,p1x,p1y,curveSplitX,curveSplitY,p2x,p2y,p3x,p3y,duration);
            },
            animatePathDrawing(ctx, x0, y0, x1, y1, x2, y2, x3, y3, duration)
            {
                var start = null;
                var _this=this;
                var step = function animatePathDrawingStep(timestamp) {
                    if (start === null)
                        start = timestamp;
                    var delta = timestamp - start,
                    progress = Math.min(delta / duration, 1);
                    // Clear canvas
                    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                    var background = new Image();
                    ctx.drawImage(background, 0, 0);
                    // Draw curve
                    let t0 = 0;
                    _this.drawBezierSplit(ctx, x0, y0, x1, y1, x2, y2, x3, y3, t0, progress);
                    if (progress < 1)
                    {
                        window.requestAnimationFrame(step);
                    }
                };
                window.requestAnimationFrame(step);
            },

            drawBezierSplit(ctx, x0, y0, x1, y1, x2, y2, x3, y3, t0, t1) {
                ctx.beginPath();
                var _this=this;
                ctx.lineWidth = 20;

                if ( t0==0.0  && t1 == 1.0)
                {
                    ctx.moveTo(x0, y0);
                    ctx.quadraticCurveTo(x1, y1, x2, y2);
                    _this.animateLine(ctx,x2, y2, x3, y3,0);
                }
                else if (t0 != t1)
                {
                    var t00 = t0 * t0,
                        t01 = 1.0 - t0,
                        t02 = t01 * t01,
                        t03 = 2.0 * t0 * t01;
                    var nx0 = t02 * x0 + t03 * x1 + t00 * x2,
                        ny0 = t02 * y0 + t03 * y1 + t00 * y2;
                    t00 = t1 * t1;
                    t01 = 1.0 - t1;
                    t02 = t01 * t01;
                    t03 = 2.0 * t1 * t01;
                    var nx2 = t02 * x0 + t03 * x1 + t00 * x2,
                        ny2 = t02 * y0 + t03 * y1 + t00 * y2;
                    var nx1 = lerp(lerp(x0, x1, t0), lerp(x1, x2, t0), t1),
                        ny1 = lerp(lerp(y0, y1, t0), lerp(y1, y2, t0), t1);
                    ctx.moveTo(nx0, ny0);
                    ctx.quadraticCurveTo(nx1, ny1, nx2, ny2);
                    if( x3 >0 ){
                    var grad = ctx.createLinearGradient(x1, y1, x3, y3);
                    grad.addColorStop(0, "rgba(255,255,255,0.3)");
                    grad.addColorStop(0.3, "rgba(50, 100, 188,1)");
                    grad.addColorStop(1, "rgba(50, 100, 188,1)");
                    ctx.strokeStyle = grad;
                    ctx.stroke();
                    }else{
                        var grad = ctx.createLinearGradient(x1, y1, x2, y2);
                        grad.addColorStop(0, "rgba(255,255,255,0.4)");
                        grad.addColorStop(1, "rgba(50, 100, 188,1)");
                        ctx.strokeStyle = grad;
                        ctx.stroke();
                        ctx.shadowBlur = 5;
                        ctx.shadowColor = "black";
                    }
                    ctx.drawImage(document.getElementById('ball_shadowUrl'), nx2-12, ny2-12,24,24);
                }
                ctx.closePath();
            },
            animateLine(ctx,x,y,xf,yf,ratio) {
                ratio = ratio || 0;
                var _this=this;
                this.drawLine(ctx,x,y,xf,yf,ratio);
                if(ratio<1) {
                    requestAnimationFrame(function() {
                    _this.animateLine(ctx,x,y,xf,yf,ratio + 0.1);
                    });
                }
            },
            drawLine(ctx,x1,y1,x2,y2,ratio) {
                ctx.beginPath();
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                x2 = x1 + ratio * (x2-x1);
                            y2 = y1 + ratio * (y2-y1);
                ctx.moveTo(localStorage.getItem('p1x'), localStorage.getItem('p1y'));
                    ctx.quadraticCurveTo(localStorage.getItem('curveSplitX'),localStorage.getItem('curveSplitY'),localStorage.getItem('p2x'),localStorage.getItem('p2y'));
                ctx.moveTo(x1,y1);
                ctx.lineTo(x2,y2);
                if(localStorage.getItem('p3x')>0){
                    var grad = ctx.createLinearGradient(localStorage.getItem('p1x'), localStorage.getItem('p1y'), localStorage.getItem('p3x'), localStorage.getItem('p3y'));
                    grad.addColorStop(0, "rgba(255,255,255,0.3)");
                    grad.addColorStop(1, "rgba(50, 100, 188,1)");
                }else{
                    var grad = ctx.createLinearGradient(localStorage.getItem('p1x'), localStorage.getItem('p1y'), localStorage.getItem('p2x'), localStorage.getItem('p2y'));
                    grad.addColorStop(0, "rgba(255,255,255,0.3)");
                    grad.addColorStop(1, "rgba(50, 100, 188,1)");
                }
                ctx.lineWidth = 16;
                ctx.strokeStyle = grad;
                ctx.stroke();
                ctx.closePath();
                ctx.drawImage(document.getElementById('ball'), x2-12,  y2-10,20,20);
                let diff = (200-x2)*3;
                if(localStorage.getItem('p3x')>50){
                    diff = ((ctx.canvas.width/2)-x2)*2;
                    diff = ((x2)+diff);
                }else{
                    diff = ((ctx.canvas.width/2)-x2)*2;
                    diff = ((x2)+diff);
                }
                ctx.beginPath();
                ctx.lineWidth = 16;
                ctx.moveTo(localStorage.getItem('p2x'),localStorage.getItem('p2y'));
                let y2_mid = y2*2<184?183:y2*2;
                ctx.lineTo(diff, y2_mid);
                ctx.strokeStyle = 'rgba(0, 0, 0,0.1)';
                ctx.stroke();
                ctx.closePath();
                ctx.beginPath();
                ctx.lineWidth = 16;
                ctx.moveTo(localStorage.getItem('p2x'),localStorage.getItem('p2y'));
                ctx.lineTo(x2, y2);
                ctx.strokeStyle = 'rgba(50, 100, 188,0.7)';
                ctx.stroke();
                ctx.drawImage(document.getElementById('ball'), x2-12,  y2-10,20,20);
                ctx.closePath();
                var initial_x= localStorage.getItem('p1x')*2;
                if(localStorage.getItem('p1x')<(ctx.canvas.width/2)){
                    var initial_x= localStorage.getItem('p1x')*2;
                }else{
                    var initial_x= localStorage.getItem('p1x')*0.7;
                }
                ctx.beginPath();
                ctx.lineWidth = 16;
                ctx.moveTo(localStorage.getItem('p2x'),localStorage.getItem('p2y'));
                ctx.lineTo(initial_x, localStorage.getItem('p1y'));
                ctx.strokeStyle = 'rgba(0, 0, 0,0.1)';
                ctx.stroke();
                ctx.closePath();
                if(localStorage.getItem('p2y')>319){
                        ctx.drawImage(document.getElementById('ball_shadowUrl'), localStorage.getItem('p2x')-20,  localStorage.getItem('p2y')-15,30,30);
                }else{
                    ctx.drawImage(document.getElementById('ball_shadowUrl'), localStorage.getItem('p2x')-20,  localStorage.getItem('p2y')-15,30,30);
                }
            },
            short_type(postion)
            {
              if(postion==257 || postion==249 || postion==241)
              {
                return 'late cut';
              }
              else if(postion==193 || postion==201 || postion==209 || postion==217 || postion==225 || postion==233)
              {
                return 'cut';
              }
              else if(postion==185 || postion==177)
              {
                return 'square cut';
              }
              else if(postion==169 || postion==161)
              {
                return 'square drive';
              }
              else if(postion==73 || postion==81 || postion==89)
              {
                return 'straight drive';
              }
              else if(postion==153 || postion==145 || postion==137 || postion==129)
              {
                return 'cover drive';
              }
              else if(postion==57 || postion==49 || postion==41 || postion==65)
              {
                return 'on drive';
              }
              else if(postion==121 || postion==113 || postion==105 || postion==97)
              {
                return 'off drive';
              }
              else if(postion==1 || postion==9 || postion==17 || postion==25 || postion==33)
              {
                return 'pull';
              }
               else if(postion==313 || postion==321 || postion==329 || postion==337 || postion==145 || postion==253)
              {
                return 'hook';
              }
               else if(postion==249 || postion==241 || postion==233)
              {
                return 'leg glance left';
              }
              else
              {
                return '';
              }
            },
             left_short_type(postion)
            {
              if(postion==297 || postion==281 || postion==289)
              {
                return 'late cut';
              }
              else if(postion==345 || postion==337 || postion==329 || postion==321 || postion==313 || postion==305)
              {
                return 'cut';
              }
              else if(postion==353 || postion==1)
              {
                return 'square cut';
              }
              else if(postion==17 || postion==9)
              {
                return 'square drive';
              }
              else if(postion==49 || postion==41 || postion==33 || postion==25)
              {
                return 'cover drive';
              }
              else if(postion==81 || postion==73 || postion==65 || postion==57)
              {
                return 'off drive';
              }
              else if(postion==105 || postion==97 || postion==89)
              {
                return 'straight drive';
              }
              else if(postion==137 || postion==129 || postion==121 || postion==113)
              {
                return 'on drive';
              }
              else if(postion==177 || postion==169 || postion==161 || postion==153 || postion==145)
              {
                return 'pull';
              }
               else if(postion==225 || postion==217 || postion==209 || postion==201 || postion==193 || postion==185)
              {
                return 'hook';
              }
               else if(postion==249 || postion==241 || postion==233)
              {
                return 'leg glance';
              }
              else
              {
                return '';
              }
            }
        },
    }
     function lerp(v0, v1, t) {
          return (1.0 - t)*v0 + t*v1;
      }
</script>
<style scoped>




/******widget css start here */

  /* last five over */
  .cricket_widget_last_over{
      padding: 7px;
      width: 100%;
      background:#fff;
  }
  .cricket_widget_last_over_inner{
      width: 100%;
      border:#c7c7c7 solid 1px;
      border-radius: 2px;
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
      overflow: hidden;
  }
  .cricket_widget_last_over_inner ul{
      margin:0;
      padding: 0;
      display: flex;
      flex-wrap: nowrap;
  }
  .cricket_widget_last_over_inner ul li{
      border-left: #c7c7c7 solid 1px;
      width: 20%;
      list-style: none;
  }
  .cricket_widget_last_over_inner ul li:first-child{
      border-radius: 2px 0px 0px 2px;
      -moz-border-radius: 2px 0px 0px 2px;
      -webkit-border-radius: 2px 0px 0px 2px;
      overflow: hidden;
  }
  .cricket_widget_last_over_inner ul li:last-child{
      border-radius: 0px 2px 2px 0px;
      -moz-border-radius: 0px 2px 2px 0px;
      -webkit-border-radius: 0px 2px 2px 0px;
      overflow: hidden;
  }
  .cricket_widget_last_over_inner ul li a{
      display: block;
      color: #333333;
      padding-top: 5px;
      padding-bottom: 3px;
      font-size: 16px;
  }
  .cricket_widget_last_over_inner ul li a.active{
      background: #656565;
  }
  .cricket_widget_last_over_inner ul li a.active b{
      color: #fff;
  }
  .cricket_widget_last_over_inner ul li a.active span{
      color: #fff;
  }
  .cricket_widget_last_over_inner ul li a b{
      display: block;
      text-align: center;
      line-height: 17px;
  }
  .cricket_widget_last_over_inner ul li a span{
      display: block;
      text-align: center;
      line-height: 17px;
      font-size: 12px;
  }
  .cricket_widget_last_over_inner ul li:first-child{
      border-left: 0px;
  }
  .circket_wrapper {
      color: #fff;
      font-size: 13px;
      position: relative;
  }
  /* index css */
  .slogan_wrap {
      background: #0f0f0f;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
  }
  .slogan_wrap .slogan_ball {
      background: url(https://sabcsport.com/assets/img/spirte.png) no-repeat -16px -185px;
      width: 19px;
      height: 20px;
      vertical-align: top;
      display: inline-block;
  }
  .slogan_wrap span.slogan_left_area {
      display: inline-block;
      width: 79%;
  }
  .slogan_date {
      display: inline-block;
      width: 20%;
      text-align: right;
  }
  .slogan_wrap span.slogan_left_area span {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 30px);
      height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
  .main_score_wrap {
      display: flex;
      background-color: #333333;
      height: 52px;
      overflow: hidden;
      align-items: center;
      justify-content: space-between;
      padding:10px;
  }
  .main_score_wrap > div {

  }
  span.score > span {
      color: #999999;
  }
  .header_right .team_block_left {
      text-align: right;
  }
  .header_team_block {
      font-size: 14px;
      white-space: nowrap;
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;
  }
  .header_team_block img {
      /*vertical-align: top;*/
      max-width: 15px;
      margin: 0 auto;
      height: auto;
  }
  .header_team_block img.ball_pic{
      max-width: 10px;
  }
  /*span.score.green {
      color: #acc440;
  }*/
  .header_team_block .over {
      color: #999999;
      font-weight: 400;
  }
  .score{
      color: #ffffff;
      font-weight: bold;
  }
  .header_team_block h3 {
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
  }
  .mobile {
      display: none;
  }
  .team_block_left {
      padding-right: 5px;
  }
  .header_center {
      font-size: 14px;
      font-weight: 300;
      padding: 0 10px;
      text-align: center;
  }
  .header_center span.sub_status {
      color: #a9c236;
      font-size: 12px;
  }
  .live_ground_content {
      position: relative;
      overflow: hidden;
      background: #1a223b;
  }
  .main_ground_img{
    border-radius: 16px;
    overflow: hidden;
  }
  .main_ground_img img{
      max-width: 100%;
      width: 100%;
  }

  .players_left {
      position: absolute;
      left: 6.666666666667%;
      top: 9%;
      width: 17%;
  }

  .wicket_popup_overlay {
      background: rgba(37,37,37,.5);
      position: absolute;
      z-index: 4;
      width: 100%;
      height: 100%;
      top:0;
      left:0;
  }

  .wicket_popup {
      position: absolute;
      border-radius: 4px;
      -moz-border-radius: 4px;
      -webkit-border-radius: 4px;
      background: #ffffff;
      color: #333;
      top: 50%;
      left: 50%;
      width: 83%;
      font-weight: 500;
      overflow: hidden;
      z-index: 3;
      /*margin-left: -45%;*/
      transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
  }
  .wicket_popup h3{
      background: #eaeaea;
      color: #333;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      flex-wrap: nowrap;
      height: 40px;
      padding-left: 20px;
      padding-right: 20px;
      line-height: 40px;
  }
  .wicket_popup h3 span.left {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 70%;
     padding-left: 20px;
      /*text-transform: uppercase;*/
  }
  .wicket_popup h3 span.left span{
      margin-left: 5px;
      margin-right: 5px;
      text-transform: lowercase;
  }
  .wicket_popup h3 span.right {
      padding-left: 7px;
      white-space: nowrap;
      width: 30%;
      text-align: right;
      font-weight: normal;
      color: #6d6d6d;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  .wicket_popup h3 span.right span   {
      color: #6d6d6d;
  }
  .wicket_popup ul {
      list-style: none;
      margin: 0;
      padding: 15px 20px;
  }
  .wicket_popup ul li  {
      display: flex;
      justify-content: space-between;
  }
  .wicket_popup ul li span.left {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 400;
      color: #333;
      padding-left: 20px;
  }
  .wicket_popup ul li span.right {
      padding-left: 7px;
      white-space: nowrap;
      font-weight: 400;
      color: #333;
  }
  .wicket_popup ul li span.right span   {
      color: #878787;
  }
  .player_live_content {
      position: absolute;
      left: 0;
      width: 100%;
      bottom:0;
      z-index: 5;
      background: rgba(37,37,37,.5);

  }
  .player_live_content_inner {
      display: flex;
      justify-content: space-between;

      padding-left: 15px;
      padding-right: 15px;
      font-size: 13px;
  }
  .player_live_content_inner ul {
      list-style: none;
      padding: 0;
      margin: 0;
  }
  .player_live_content_inner ul li {
      display: table-row;
      justify-content: space-between;
      align-items: center;
      text-shadow: 1px 1px black;
  }
  .player_live_content_inner ul span {
      padding-left: 10px;
      text-align: center;
      display: table-cell;
  }
  .player_live_content_inner ul span.playername {
      text-align: right;
  }
  .player_live_content_inner ul span.playescore {
      font-weight: 900;
  }
  .live_footer_content {
      background: #333333;
  }
  .live_footer_top {
      display: flex;
      padding: 0 15px;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #444444;
      min-height: 40px;
  }
  .recent_over_list {
      list-style: none;
      padding: 0;
      margin: 0;
      color: #000000;
      display: flex;
      font-size: 14px;
  }
  .recent_over_list li {
      margin-right: 10px;
      color: #fff;
  }
  .recent_over_list li:last-child {
      margin-right: 0px;
  }
  .recent_over_list li.wicket span  {
      background: #ed1c24;
      color: #fff;
  }
  li span sub {
      bottom: 0;
      /*margin-left: -1px;*/
      font-size: 10px;
  }
  .recent_over_list li span {
      min-width: 26px;
      height: 26px;
      line-height: 27px;
      text-align: center;
      background: #fff;
      color: #333;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      padding: 0 4px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      align-content: center;
      justify-content: center;
      vertical-align: middle;
  }
  .footer_status_list {
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 13px;
  }
  .footer_status_list li {
      display: table-row;
  }
  .footer_status_list li span {
      display: table-cell;
      vertical-align: bottom;
  }
  .footer_status_list li span.footer_status_left {
      text-align: right;
      padding-right: 7px;
      color: #999;
  }
  .footer_status_list li span.footer_status_right {
      color: #fff;
      text-align: right;
      font-weight: 900;
  }
  .live_footer_bot {
      padding: 8px 15px;
      font-size: 14px;
  }
  .last_recent_overs {
      display: flex;
      line-height: 14px;
      height: 14px;
      justify-content: center;
      align-items: center;
  }
  .last_recent_overs h4 {
      font-weight: normal;
      padding-right: 10px;
      color: #999999;
  }
  .last_recent_overs ul {
      list-style: none;
      padding: 0;
      margin: 0;
  }
  .last_recent_overs ul li {
      display: inline-block;
      color: #fff;
      border-right: 1px solid #585858;
      line-height: 14px;
      padding-left: 10px;
      padding-right: 13px;
  }
  .last_recent_overs ul li span.wicket {
      color: #ed1c24;
  }
  .last_recent_overs ul li:last-child {
      border-right: 0 ;
  }
  .ball_img{
      /* display: flex;
      flex-wrap: nowrap; */
      width: 15px;
  }
  .main_score_wrap .flex_div {
      line-height: 26px;
      margin-right: 15px;
  }
  .main_score_wrap .flex_div:last-child {
      margin-right: 0;
  }

  .team_inn_block {
      display: inline-block;
      vertical-align: top;
      font-size: 13px;
      display: table-cell;
      padding-bottom: 8px;
  }
  .team_inn_block .innerteamblock {
      background: #303030;
      color: #fff;
      border-radius: 30px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 17px;
      text-align: center;
      display: block;
      vertical-align: top;
  }
  .team_inn_block.green .innerteamblock {
      background: #267f00;
  }
  .team_inn_block span {
      font-size: 11px;
      vertical-align: top;
      display: inline-block;
  }
  .both_team_block {
      font-size: 17px;
      display: table-cell;
      vertical-align: top;
      padding-right: 3px;
  }
  .both_team_block img {
      vertical-align: top;
  }
  .both_team_block > span {
      width: calc(100% - 25px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      vertical-align: top;
      padding-right: 5px;
      /*display: table-cell;*/
  }
  .inning_seprater_block {
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;
      vertical-align: top;
      padding-top: 3px;
  }
  .inning_over_block {
      font-size: 13px;
      display: inline-block;
      vertical-align: top;
      padding-top: 4px;
      padding-left: 4px;
  }
  .inning_over_block:last-child {
      /*margin-top: 9px;*/
  }
  .main_score_wrap *:empty {
      /*display: none;*/
  }
  /* , .inning_over_block , .inning_seprater_block */
  .both_team_block , .team_inn_block .innerteamblock  {
      line-height: 18px;
      padding-top: 3px;
      padding-bottom: 3px;
      /*margin-bottom: 7px;*/
  }
  .both_team_block:last-child , .team_inn_block:last-child , .inning_over_block:last-child , .inning_seprater_block:last-child {
      /*margin-bottom: 0;*/
  }
  .main_live_score_reap_bg {
      background: url(https://sabcsport.com/assets/img/ground_repeat_bg.jpg) repeat-x center top;
  }
  .main_live_score_wrap {
      background: url(https://sabcsport.com/assets/img/main_cricket_bg.jpg) center top no-repeat;
      background-size: contain;
      height: 243px;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
  }
  .live_bat_bowl.left {
      float: left;
  }
  .live_bat_bowl.right {
      float: right;
  }
  .live_bat_bowl ul {
      list-style: none;
      padding: 0;
      margin: 0;
  }
  .live_bat_bowl ul li {
      vertical-align: middle;
      line-height: 17px;
      height: 17px;
  }
  .bat_bowl_icon {
      width: 12px;
      height: 13px;
      display: inline-block;
      margin-right: 2px;
  }
  .bat_bowl_icon.ball {
      background: url(https://sabcsport.com/assets/img/spirte.png) no-repeat -35px -199px;
      position: relative;
      vertical-align: top;
      top: 4px;

  }
  .bat_bowl_icon.bat {
      background: url(https://sabcsport.com/assets/img/spirte.png) no-repeat -35px -185px;
      position: relative;
      vertical-align: top;
      top: 2px;
  }
  .player_name {
      display: inline-block;
      min-width: 110px;
  }
  .current_over_wrap {
      margin-top: 35px;
      margin-bottom: 20px;
  }
  .current_over_wrap ul{
      text-align: center;
  }
  .current_over_wrap ul li{
      display: inline-block;
      margin-left: 3px;
      margin-right: 3px;
  }
  .current_over_wrap ul li.wicket span{
      color: red;
  }
  .current_over_wrap ul li span{
      display: inline-block;
      background: #fff;
      color: #000;
      min-width: 30px;
      padding-left: 7px;
      padding-right: 7px;
      height: 30px;
      line-height: 30px;
      border-radius: 50%;
      font-family: 'Roboto';
      font-weight: normal;
      text-shadow: initial;
      font-size: 20px;
  }
  .recent_over_wrap {
      text-align: center;
  }
  .recent_over_hd {
      display: inline-block;
  }
  .recent_over_wrap ul {
      display: inline-block;
      list-style: none;
      padding: 0;
      margin: 0;
  }
  .recent_over_wrap ul li span {
      margin-left: 5px;
  }
  .recent_over_wrap ul li {
      display: inline-block;
      border-right: 1px solid #fff;
      /*padding-left: 10px;*/
      padding-right: 10px;
  }
  .recent_over_wrap ul li:last-child {
      border-right: 0;
  }
  .live_match_stat_wrap {
      margin-top: 9px;
      padding-top: 5px;
      border-top: 1px solid #fff;
  }
  .live_match_stat.left {
      float: left;
  }
  .live_match_stat.right {
      float: right;
  }
  .live_match_stat ul {
      list-style: none;
      padding: 0;
      margin: 0;
  }
  .live_match_stat ul li span {
      display: inline-block;
  }
  .live_match_stat_cell {
      min-width: 113px;
  }
  .collapse_btn {
      display: block;
      min-height: 10px;
      background: #b1c339;
      cursor: pointer;
  }
  .collapse_btn span {
      display: block;
      width: 50px;
      margin: 0 auto;
      height: 16px;
      background: #b1c339;
      position: relative;
      /*top: -5px;*/
      border-radius: 3px;
      text-align: center;
  }
  .collapse_btn span samp {
      display: inline-block;
      width: 16px;
      height: 11px;
      background: url(https://sabcsport.com/assets/img/spirte.png) no-repeat -16px -205px;
  }
  .collapse_btn.up_arrow span {
      top: -5px;
  }
  .collapse_btn.up_arrow span samp {
      background-position: -16px -216px;
  }
  .batting_tbl {
      font-size: 14px;
  }
  .parnership_table , .extraa_table , .bowler_tbl , .batting_tbl tr:not(.show_con) {
      font-size: 14px;
  }
  /* index css */

  /* score cared  */
  .scoreboard_wrap  {
      /*background: url(../images/scorcard_bg.png) no-repeat left 80%;
      background-size: cover;*/
      /*padding: 10px;*/
  }
  .scoreboard_inner_wrap {
      background: #333333;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
  }
  .scorboard_top_team {
      display: flex;
      font-size: 18px;
  }
  .scorboard_top_team  div {
      width: 33.33333333%;
      padding-left: 10px;
      padding-right: 10px;
  }
  .scorboard_top_team  div:first-child {
      padding-left: 0;
  }
  .scorboard_top_team  div:last-child {
      padding-right: 0;
      text-align: right;
  }
  .score_card_team_status {
      text-align: center;
  }

  .inning_tabs_wrap {
      width: 100%;
      background: #444444;
      border-radius: 4px 4px 0 0;
      -moz-border-radius: 4px 4px 0 0;
      -webkit-border-radius: 4px 4px 0 0;
      overflow: hidden;
  }
  .inning_tabs_wrap ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      height: 53px;
      border-bottom: #787878 solid 1px;
  }
  .inning_tabs_wrap ul li {
      width: 50%;
      background: url(https://sabcsport.com/assets/img/inning_tabs_wrap_list_border.png);
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 2px 28px;
  }
  .inning_tabs_wrap ul li:first-child {
      background: none;
  }
  .inning_tabs_wrap ul li a {
      width: 100%;
      text-align: center;
      color: #999999;
      font-size: 16px;
      font-family: 'Roboto';
      font-weight: 500;
      display: block;
      line-height: 47px;
      border-bottom: 4px solid #444444;
  }
  .inning_tabs_wrap ul li a .desktop_team_name {
      overflow: hidden;
      padding-left: 3px;
      padding-right: 3px;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      font-weight: bold;
  }
  .inning_tabs_wrap ul li a:hover {
      text-decoration: none;
      color: #fff;
  }
  .inning_tabs_wrap ul li.active a {
      text-decoration: none;
      color: #fff;
      border-bottom: 4px solid #00a622;
  }
  .score_table {}
  .score_table table {
      width: 100%;
  }
  .score_table table th {
      text-align: left;
      padding: 14px 12px;
      background: #eaeaea;
      color: #333;
  }
  .score_table.batting_tbl th:nth-child(1), .score_table.batting_tbl td:nth-child(1){
      width: 50%;
  }
  .score_table.batting_tbl th:nth-child(2),
  .score_table.batting_tbl td:nth-child(2),
  .score_table.batting_tbl th:nth-child(3),
  .score_table.batting_tbl td:nth-child(3),
  .score_table.batting_tbl th:nth-child(4),
  .score_table.batting_tbl td:nth-child(4),
  .score_table.batting_tbl th:nth-child(5),
  .score_table.batting_tbl td:nth-child(5){
      width: 10%;
  }
  .score_table.batting_tbl th:nth-child(1),
  .score_table.batting_tbl th:nth-child(2),
  .score_table.batting_tbl td:nth-child(1),
  .score_table.batting_tbl td:nth-child(2) {
      text-align: left;
      color: #333;
  }
  .score_table.batting_tbl th,
  .score_table.batting_tbl td {
      text-align: center;
      color: #333;
  }
  .score_table.bowler_tbl th,
  .score_table.bowler_tbl td {
      text-align: center;
  }
  .score_table.bowler_tbl th:nth-child(1),
  .score_table.bowler_tbl td:nth-child(1) {
      text-align: left;
  }
  .score_table table td {
      text-align: left;
      padding: 8px 12px;
      border-bottom: #ededed solid 1px;
      font-weight: 400;
      color: #333;
  }
  .score_table table td span {
      font-weight: 300;
      color: #969696;
      font-size: 13px;
      line-height: 13px;
  }
  .score_table table .active td {
      background: #e5f6e9;
  }
  .score_table table .active td span {
      color: #00a826;
      font-weight: bold;
  }
  .score_table table {
      width: 100%;
  }
  .extraa_table {
      background: #fff;
      /*display: flex;*/
      flex-wrap: nowrap;
  }
  .extraa_table .left {
      text-align: left;
      width: 45%;
      float: left;
  }
  .extraa_table .right {
      text-align: right;
      width: 40%;
      float: right;
  }
  .extraa_table table {
      width: 100%;
  }
  .extraa_table th {
      font-weight: normal;
      padding: 8px 12px;
      text-align: center;
      color: #333;
  }
  .extraa_table.score_table table th {
      background-color: #fff;
  }
  .extraa_table th span {
      display: block;
      font-weight: bold;
  }
  .extraa_table th strong {
      display: block;
      font-weight: bold;
  }
  .inning_tab_seprate_table {
      width: 100%;
      background: #fff;
      border-radius: 4px;
      -moz-border-radius: 4px;
      -webkit-border-radius: 4px;
      margin-bottom: 5px;
      overflow: hidden;
  }
  .inning_tab_seprate_table:nth-child(1) {
      border-radius: 0 0 4px 4px;
      -moz-border-radius: 0 0 4px 4px;
  }
  .parnership_table tr th {
      text-align: center;
  }
  .parnership_table tr td {
      text-align: center;
  }
  .parnership_table tr td:nth-child(1) {
      text-align: left;
  }
  .parnership_table tr th:nth-child(1) {
      text-align: left;
  }
  /* score cared  */
  /* commentry css  */
  .commentry_content_wrap {
      /* background: #ffffff;*/
      padding-bottom: 10px;
  }
  .commentry_content_wrap.commentary_bg {
      background: #ffffff;
  }
  .commentry_para_block p {
      border-bottom: 1px solid #acc540;
      padding-bottom: 10px;
      padding-top: 10px;
  }
  .commentry_para_block p:last-child {
      border-bottom: 0;
  }
  .overs_commentry {}
  #overs_commentry {
      display: none;
  }
  .overs_commentry ul {
      list-style: none;
      padding: 0;
      margin: 0;
  }
  .overs_commentry .over_heading {
      display: block;
      padding: 5px 10px;
      background: #eaeaea;
      color: #333;
      font-size: 15px;
      font-family: 'Roboto';
      font-weight: 700;
      position: relative;
      padding-right: 40px;
  }
  .overs_commentry .over_heading .over_score{
      float: right;
      position: absolute;
      right: 10px;
      top: 8px;
  }
  .overs_commentry .over_content {
      display: block;
      line-height: 20px;
      padding: 5px 10px;
      color: #656565;
      background: #fff;
      font-size: 15px;
  }
  .over_finished .over_heading {
      background: #fff;
      /*margin-bottom: 10px;*/
  }
  .over_wicket .over_heading {
      background: #ff999c;
  }
  /* commentry css  */

  /* Matchinfo css  */
  .match_info_wrap ul{
      list-style: none;
      padding: 0;
      margin: 0;
  }
  .match_info_wrap ul li {
      border-bottom: #333333 solid 1px;
  }
  .match_info_wrap ul li span{
      float: left;
      width: 30%;
      line-height: 34px;
      padding-left: 7px;
      padding-right: 7px;
      color: #999999;
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 14px;
  }
  .match_info_wrap ul li samp{
      float: left;
      width: 70%;
      line-height: 34px;
      padding-left: 7px;
      padding-right: 7px;
      font-family: 'Roboto';
      font-size: 14px;
      text-align: right;
  }
  .match_info_wrap ul li samp b{
      font-weight: 400;
      display: block;
  }
  /* Matchinfo css  */

  /* Matchinfo css  */
  .lineup_wrap {
      border-top: 1px solid #fff;
      margin-top: 10px;
      padding-top: 10px;
  }
  .lineup_wrap h3 {
      background: #acacac;
      color: #000;
      padding-left: 10px;
      padding-right: 10px;
      line-height: 30px;
      margin-bottom: 5px;
      font-weight: 700;
      font-family: 'Roboto';
      font-size: 16px;
  }
  .lineup_wrap ul {
      list-style: none;
      padding: 0;
      margin: 0;
  }
  .lineup_wrap ul li {
      line-height: 24px;
      font-size: 14px;
      padding-left: 10px;
      padding-right: 10px;
  }
  .lineup_left {
      float: left;
      width: 49%;
      margin-right: 1%;
  }
  .lineup_right {
      float: left;
      width: 49%;
      margin-left: 1%;
  }
  .cric_slogen {
      text-align: center;
  }
  /* Matchinfo css  */

  /* wickets */
  .wkt_hd.left {
      float: left;
  }
  .wkt_hd.left span {
      margin-right: 7px;
  }
  .wkt_hd.right {
      float: right;
  }
  /* wickets */
  iframe {
      border:0;
      height: inherit;
      width: 100%;
  }
  .mobile_team_name {
      display: none;
  }
  .showinmobile {
      display: none;
  }

  .upcuming_text {
      background: rgba(34, 34, 34, 0.8) none repeat scroll 0 0;
      border-top: 1px solid #666;
      padding: 5px 10px;
      height: 29px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
  }
  .oneday .inning_tabs_wrap ul li {
      width: 50%;
  }
  .team1 {
      /*margin-bottom:9px;*/
      display: table-row;
  }
  .team2 {
      display: table-row;
  }
  /*.overs_commentry ul li:not(:first-child) {
      border-top: solid 1px #acacac;
  }
  .overs_commentry ul li.over_wicket {
      border-top: solid 1px #ff4747;
  }*/
  /*.overs_commentry ul li.over_finished{
      border-top: solid 1px #d8da33;
      margin-top: 10px;
  }*/
  .main_score_table_wrap {
      display: table;
  }
  header {
      max-height: 114px;
      overflow: hidden;
  }
  .scoreboard_wrap > p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
  .both_team_block .desktop_team_name {
      line-height: 14px;
      vertical-align: top;
  }
  .fall_wicket {
      display: block;
      margin: 10px auto 0;
      text-align: center;
      height: 17px;
  }

  .graph_main_wrap {
      margin-bottom: 5px;
  }
  .graph_main_wrap canvas {
      background-color: #2c2c2c;
  }
  .graph_bottom_area {
      display: flex;
      display: -webkit-flex;
      flex-wrap: wrap;
      justify-content: space-between;
      -webkit-flexjustify-content: space-between;
      text-align: center;

  }
  .graph_bottom_area > div {
      display: flex;
      flex-wrap: nowrap;
      display: -webkit-flex;
      justify-content: space-between;
      -webkit-flexjustify-content: space-between;
  }
  .graph_bottom_area h3 {
      background: url(https://sabcsport.com/assets/img/white_bg_first_team_info.png);
      background-size: 16px;
      background-position: left 10px;
      background-repeat: no-repeat;
      padding:2px 22px 0;
      line-height: 28px;
      height: 28px;
      font-size: 16px;
      color: #fff;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: normal;
  }
  .graph_bottom_area .green h3 {
      background-image: url(https://sabcsport.com/assets/img/green_bg_first_team_info.png);
  }
  .graph_bottom_area_right h3  {
      background-position-x: right;
  }
  .graph_bottom_area_right ul {
      background: #f80012;
  }
  .graph_bottom_area_right ul li {
      border-right: 1px solid #d1000f;
  }
  .overlay_popup {
      background: rgba(37,37,37,.5);
      position: absolute;
      z-index: 4;
      width: 100%;
      height: 100%;
      top:0;
      left:0;
  }
  .center_popup {

  }

  .white_block_popup {
      width: 83%;
      position: absolute;
      left: 50%;
      top:50%;
      transform: translate(-50% , -50%);
      -moz-transform: translate(-50% , -50%);
      -webkit-transform: translate(-50% , -50%);
      background: #fff;
      border-radius: 4px;
      -moz-border-radius: 4px;
      -webkit-border-radius: 4px;
      overflow: hidden;
      z-index: 5;
  }

  .white_top_header {
      display: flex;
      display: -ms-flex;
      flex-wrap: wrap;
      flex-wrap: wrap;
      background: #eaeaea;
      height: 30px;
      line-height: 30px;
  }
  .white_top_left {
      color: #333;
      position: relative;
      padding: 0px 10px;
      font-size: 14px;
      width: 30%;
      font-weight: bold;
      /*line-height: 26px;*/
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  .white_top_right {
      padding: 0px 10px;
      color: #656565;
      width: 70%;
      text-align: right;
      /*line-height: 26px;*/
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 300;
  }
  .white_content_block {
      background: #fff;
      padding: 10px;
  }
  .white_content_block {
      color: #505050;
  }
  .player_profile_detail {
      color: #505050;
      display: flex;
      display: -ms-flex;
      flex-wrap: wrap;
      -ms-flex-wrap: wrap;
  }
  .player_profile_detail .left {
      padding-right: 20px;
      width: 84px;
      height: 43px;
  }
  .player_profile_detail .left img{
      width:100%;
      box-shadow: 1px 1px 7px #aaa;
  }
  .player_profile_detail .right {
      width: calc(100% - 84px);
      width: -moz-calc(100% - 84px);
      width: -webkit-calc(100% - 84px);
  }
  .table_repeat_area h3 {
      font-size: 11px;
  }
  .table_repeat_area ul {
      list-style: none;
      padding: 0;
      margin: 0;
  }
  .table_repeat_area ul li {
      display: flex;
      display: -ms-flex;
      flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      justify-content: space-between;
  }
  .table_repeat_area ul li .table_block {
      width:33.333333333333%;
      padding-right: 2%;
      /*    display: flex;
          display: -ms-flex;
          flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          justify-content: space-between;*/
  }
  /*.table_repeat_area ul li .table_block .table_block_inner{
      display: flex;
      justify-content: space-between;
      width:100%;
  }*/
  .table_repeat_area ul li .table_block span {
      display: inline-block;
      padding-right: 3px;
      color: #999999;
      font-size: 11px;
      width: 30px;
  }
  .table_repeat_area ul li .table_block.first span {
      width:50px;
  }
  .table_repeat_area .table_block_ul li  strong {
      display: inline-block;
      font-weight: bold;
      color: #333;
      font-size: 11px;
  }

  .table_repeat_area {
      border-bottom: 1px solid #cccccc;
      padding-bottom: 10px;
      margin-bottom: 10px;
  }
  .table_repeat_area:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: 0;
  }
  .list_content {
      list-style: none;
      padding: 0;
      margin: 0;
  }
  .list_content li  {
      /*padding-bottom: 10px;*/
  }
  .list_content li .cirle  {
      width: 7px;
      height: 7px;
      line-height: 7px;
      display: inline-block;
      border-radius: 50%;
      background: #439539;
      margin-right: 3px;
  }
  .progress_wrapper {
      float: right;
      width: 50px;
      height: 50px;
      margin-bottom: 15px;
      position: relative;
  }
  .progress_wrapper strong {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50% , -50%);
      display: block;
      /*line-height: 100%;*/
      width: 50px;
      text-align: center;
      line-height: 11px;
      font-size: 11px;
      color: #439539;
  }
  .progress_wrapper canvas {
      width: 50px;
  }

  .wagon_wheel_right {
      background: #2c2c2c;
      border-radius: 7px;
      width: calc(100% - 110px);
      height: auto;
      margin-top: 0;
      margin-left: 110px;
      overflow: hidden;
  }
  .wagon_wheel_top_content {
      padding: 10px 20px 10px 120px;
  }
  .wagon_wheel_bot_content {
      padding: 10px 10px 10px 120px;
      background: #000000;
  }
  .wagon_wheel_top_content h3 {
      color: #fff;
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
  }
  .wagon_wheel_top_content h3 strong{
      /*font-weight: normal;*/
  }
  .wagon_wheel_top_content h3 span {
      color: #999999;
      font-size: 12px;
      text-decoration: underline;
      font-weight: 300;
  }
  .inning_detail_ul {
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 14px;
  }
  .inning_detail_ul li {
  }
  .inning_detail_ul li span {
      color: #00a826;
  }
  .inning_btn_ul {
      list-style: none;
      padding: 5px 0 0 0;
      margin: 0;
  }
  .inning_btn_ul li {
      display: inline-block;
      margin-right: 3px;
  }
  .inning_btn_ul li a {
      display: inline-block;
      background: #000000;
      border-radius: 3px;
      color: #999999;
      padding: 3px 7px;
      font-size: 11px;
  }
  .inning_btn_ul li.active a ,
  .inning_btn_ul li a:hover {
      background: #00a826;
      color: #fff;
  }
  .inning_action_ul {
      list-style: none;
      padding: 0;
      margin: 0;
  }
  .inning_action_ul li  {
      display: inline-block;
      padding-right: 4px;
  }
  .inning_action_ul li:last-child  {
      padding-right: 0;
  }
  .inning_action_ul li span {
      display: inline-block;
      line-height: 35px;
      min-width: 35px;
      border-radius: 50%;
      font-size: 16px;
      text-align: center;
  }
  .inning_action_ul li.ones span {
      background: #bd08f3;
      color: #fff;
  }
  .inning_action_ul li.twoes span {
      background: #5b2c02;
      color: #fff;
  }
  .inning_action_ul li.threes span {
      background: #fff41f;
      color: #111111;
  }
  .inning_action_ul li.fours span {
      background: #ebebeb;
      color: #111111;
  }
  .inning_action_ul li.fives span {
      background: #113e65;
      color: #fff;
  }
  .inning_action_ul li.sixes span {
      background: #ed2e12;
      color: #ffffff;
  }
  .inning_action_ul li.two_threes span {
      background: #ffc421;
      color: #111111;
      border-radius: 20px;
      padding-left: 15px;
      padding-right: 15px;
  }
  .animation_wrap {
    z-index: 999;
    transition: .5s cubic-bezier(0.46, 0.03, 0.52, 0.96);
    position: static;
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    
  }
    .animation_wrap img {
        width: 250px;
        height: 250px;
        object-fit: contain;
    }
  .progress_wrapper canvas {
      width: 50px !important;
      height: 50px !important;
  }
  @keyframes anaction {
      0% {
          transform: translate(-50% , -50%) rotate(0deg) scale(.01);
      }
      60% {
          transform:translate(-50% , -50%) rotate(720deg) scale(1);
      }
      100% {
          transform:translate(-50% , -50%) rotate(720deg) scale(1);
      }
  }
  @-webkit-keyframes anaction {
      0% {
          transform: translate(-50% , -50%) rotate(0deg) scale(.01);
      }
      60% {
          transform:translate(-50% , -50%) rotate(720deg) scale(1);
      }
      100% {
          transform:translate(-50% , -50%) rotate(720deg) scale(1);
      }
  }

  .overlay_popup_full {
      background: #fff;
      color: #505050;
      top:100%;
      bottom:20px;
      overflow: hidden;
      margin: 65px 15px 20px;
      height: auto;
      width: calc(100% - 30px);
      border-radius: 5px;
      overflow: inherit;

  }
  .overlay_popup_full.animation_top {
      top:40px;
      transition: all .8s;
      font-size: 12px;
      bottom: 1px;
  }
  .overlay_popup_full_inner {
      height: calc(100vh - 80px);
      /*top:52px;*/
      /*height: 100%;*/
  }
  .overlay_popup_full_inner p span{
      font-size: 16px;
      font-weight: 400;
  }
  .overlay_popup_full_inner h3.tophead {
      background: #f8f8f8;
      line-height: 28px;
      padding-left: 44px;
      padding-right: 10px;
      position: relative;
      border-radius: 5px 5px 0 0;

  }
  .overlay_popup_full_inner .cross_btn img {
      width:14px;

  }
  .overlay_popup_full_inner h3 img {
      margin-right: 5px;
  }
  .overlay_popup_full_inner img.fire_img {
      margin-right: 5px;
      position: absolute;
      left:10px;
      width: 26px;
      bottom: 0;
  }
  .overlay_popup_full_inner h3 span , .overlay_popup_full_inner h3 img {
      vertical-align: middle;
      display: inline-block;
  }
  .overlay_popup_full_inner p  {
      /*height: auto;*/
      height: calc(100% - 110px);
      padding: 10px 10px;
      /*font-weight: bold;*/
      line-height: 16px;
      display: flex;
      overflow: auto;
      /*align-items: center;*/
      min-height: 38px;
  }
  .match_winner_comparison {

  }
  .match_winner_comparison h3 {
      /*color: #00a826;*/
      background: #f8f8f8;
      font-weight: 300;
      font-size: 16px;
      line-height: 28px;
      padding-left: 10px;
      padding-right: 10px;

  }
  .match_winner_comparison ul {
      display: flex;
      list-style: none;
      padding-left: 0;
      padding-right: 0;
      border-radius: 0px 0px 5px 5px;
      overflow: hidden;
  }
  .match_winner_comparison ul li {
      background: #fff;
      flex-grow: 1;
      /*color: #00a826;*/
      cursor: pointer;
      height: 54px;
      border-right:1px solid #c1c1c1;
      text-align: center;
      padding-right:10px;
      padding-left:10px;
      position: relative;
      margin: 0;
      padding-top: 7px;
      padding-bottom: 7px;
  }
  .match_winner_comparison ul li.active {
      background: #656565 url('https://sabcsport.com/assets/img/right_check.png') no-repeat right top;
      color: #fff;
      border-right: 0;
  }
  .match_winner_comparison ul li:hover{
      background: #656565;
      color: #fff;
      border-right: 0;
  }
  .match_winner_comparison ul li span {
      display: block;
      font-size: 16px;
      font-weight: 300;
  }
  .match_winner_comparison ul li strong {
      font-size: 18px;
      line-height: 21px;
  }
  .match_winner_comparison ul li:first-child {
      padding-left: 20px;
  }
  .match_winner_comparison ul li:last-child {
      margin-right: 0px;
      border-right: 0;
  }
  .match_winner_comparison ul li:first-child:before {
      display: none;
  }
  .match_winner_comparison ul li:last-child:after {
      display: none;
  }

  span#countdown-1 {
      /*background: #EAEAE9;*/
      float: right;
      /*    margin-top: 6px;
          padding-left: 5px;
          padding-right: 5px;*/
  }

  .cross_btn {
      float: right;
      padding-left: 7px;
      cursor: pointer;
  }
  .match_info_wrap{
      background: #2c2c2c;
  }
  .match_info_control{
      width: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: center;
  }
  .match_info_control ul{
      margin: 0;
      padding: 0;
  }
  .match_info_control ul li{
      display: inline-block;
      zoom:1;
      *display:inline;
  }
  .match_info_control ul li a{
      width: 10px;
      height: 6px;
      display: block;
      background: #858585;
      border-radius: 2px;
  }
  .match_info_control ul li a.active{
      background: #fff;
  }

  g.tick:nth-child(5n+1) {
      display: block;
  }
  g.tick {
      display: none;
  }
  g.tick:nth-child(2) {
      display: block;
  }
  g.tick:nth-last-child(2) {
      display: block;
  }


  @media (max-width:767px){
      .graph_bottom_area h3 {
          font-size: 13px;
      }
      .graph_bottom_area ul {
          font-size: 13px;
      }
      .wicket_popup h3{
          padding-left: 10px;
          padding-right: 10px;
          font-size:12px;
          height: 30px;
          line-height: 30px;
      }
      .wicket_popup ul{
          padding: 10px;
      }
      .inning_tabs_wrap ul li{
          background-size: auto 20px;
      }
      .inning_tabs_wrap ul li a{
          line-height: 40px;
          font-size: 13px;
      }
      .inning_tabs_wrap ul{
          height: 44px;
      }

  }
  @media (max-width:599px){
      .cricket_widget_last_over_inner ul li a{
          font-size: 13px;
          padding-top: 3px;
          padding-bottom: 2px;
      }
      .cricket_widget_last_over_inner ul li a b {
          line-height: 14px;
      }
      .cricket_widget_last_over_inner ul li a span {
          line-height: 14px;
          font-size: 11px;
      }
      .graph_bottom_area ul {
          padding-left: 0;
          padding-right: 0;
      }
  }
  @media (max-width:479px){
      .graph_bottom_area {
          flex-wrap: wrap;
      }
      .graph_bottom_area_left {
          width: 50%;
      }
      .graph_bottom_area_right {
          width:50%;
          justify-content: flex-end !important;
      }
      .graph_bottom_area h3 {
          font-size: 12px;
          display: block;
      }
      .graph_bottom_area ul {
          width: 50%;
      }
      .graph_bottom_area ul li {
          width: 50%;
          font-size: 12px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
      }
  }
  @media (max-width: 640px) {

      .current_over_wrap {
          margin-top: 35px;
      }
      .scorboard_top_team {
          font-size: 14px;
      }
      .scorboard_top_team .score_card_team1 {
          width: 40%;
      }
      .scorboard_top_team .score_card_team_status {
          width: 20%;
      }
      .scorboard_top_team .score_card_team2 {
          width: 40%;
      }
      .match_info_wrap ul li samp {
          font-size: 13px;
      }
      .match_info_wrap ul li span {
          font-size: 12px;
      }
      .main_live_score_wrap {
          background-image: url(https://sabcsport.com/assets/img/main_cricket_bg_mob.jpg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100%;
      }

      .wagon_wheel_right {
          width: calc(100% - 75px);
          margin-left: 75px;
      }
      .wagon_wheel_top_content {
          padding-left: 80px;
      }
      .wagon_wheel_bot_content {
          padding-left: 80px;
      }


  }
  @media (max-width: 600px) {

      .both_team_block {
          /*font-size: 14px;*/
      }
      .header_team_block {
          font-size: 13px;
      }
      .header_team_block h3 {
          font-size: 14px;
      }
      .header_center {
          font-size: 12px;
      }
      .header_center span.sub_status {
          font-size: 11px;
      }
      .player_live_content_inner {
          font-size: 14px;
      }
      .last_recent_overs ul li {
          font-size: 14px;
      }
      .last_recent_overs h4 {
          font-size: 14px;
      }
      .footer_status_list {
          font-size: 13px;
      }
      .recent_over_list li span {
          font-size: 13px;
          min-width: 25px;
          height: 25px;
          line-height: 25px;
      }
      .mobile {
          display: block;
      }
      .desktop {
          display: none;
      }

  }
  @media (max-width: 480px) {
      .match_winner_comparison h3{
          font-size: 12px;
      }
      .overlay_popup_full_inner p span {
          font-size: 14px;
      }
      .match_winner_comparison ul li span{
          font-size: 13px;
      }
      .match_winner_comparison ul li strong{
          font-size: 15px;
      }
      /*    .overlay_popup_full.animation_top {
              top: -18px;
              width: calc(100% - 24px);
              margin: 30px 12px 20px;
          }*/
      .extraa_table .right th {
          text-align: center;
          padding-left: 3px;
          padding-right: 3px;
          vertical-align: middle;
      }
      .extraa_table.score_table table th {
          padding: 2px;
      }
      .score_table table th:nth-child(1),.score_table table td:nth-child(1) {
          padding-left: 5px;
      }
      .score_table table th:last-child,.score_table table td:last-child {
          padding-right: 5px;
      }
      .score_table table th {
          font-size: 12px;
          padding: 5px 2px;
      }
      .score_table table td {
          font-size: 12px;
          padding: 2px 2px;
          color:#333;
          line-height: 14px;
          height: 25px;
      }
      .score_table table td span{
          font-size: 11px;
      }
      .wicket_popup ul{
          padding-top: 10px;
          padding-bottom: 10px;
      }
      .left_sport_icon_header{
          height: 35px;
      }
      .header_team_block > div > span.score {
          font-size: 12px;
          line-height: 12px;
      }
      .slogan_wrap span {
          /*font-size: 12px;*/
      }
      .main_score_wrap {
          /*padding: 5px;*/
      }
      .main_score_wrap .flex_div {
          margin-right: 4px;
      }
      .main_score_wrap .both_team_block {
          /* font-size: 13px;*/
      }
      .inning_seprater.flex_div {
          display: none;
      }
      .both_team.flex_div .both_team_block:last-child {
          text-align: left;
      }
      .inning_score.flex_div .team_inn_block {
          margin-bottom: 5px;
          margin-top: 0px;
          border-radius: 3px;
          margin-right: 0px;
          padding-left: 4px;
          padding-right: 4px;
          font-size: 13px;
          min-height: 26px;
      }
      .inning_score.flex_div .team_inn_block:last-child {
          margin-right: 0px;
          margin-left: 0;
      }
      .main_score_wrap .inning_over_block {
          font-size: 10px;
          text-align: left;
      }
      .main_live_score_reap_bg {
          background: url(https://sabcsport.com/assets/img/ground_repeat_bg_mob.jpg) repeat-x center top;
      }
      .main_live_score_wrap {
          background-position: center 0px;
          font-size: 12px;
          background: url(https://sabcsport.com/assets/img/main_cricket_bg_mob.png) center 0 no-repeat;
          padding-left: 5px;
          padding-right: 5px;
          padding-top: 5px;
          height: inherit;
          background-size: 100%;
      }

      .current_over_wrap {
          margin-top: 5px;
          margin-bottom: 10px;
      }
      .current_over_wrap ul li span {
          font-size: 12px;
          height: 21px;
          line-height: 22px;
          min-width: 21px;
          padding-left: 4px;
          padding-right: 4px;
      }
      .live_bat_bowl.clearfix {
          min-height: 34px;
      }
      .live_bat_bowl ul li {
          line-height: 15px;
      }
      .player_name {
          min-width: 87px;
      }

      .live_bat_bowl.left .player_name  {
          width: calc(100% - 96px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: middle;
      }
      .live_bat_bowl.left .player_stats  {
          display: inline-block;
      }
      .live_bat_bowl.right .player_name {
          width: calc(100% - 75px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: middle;
      }
      .scoreboard_inner_wrap {
          padding: 0px 7px;
      }
      .scoreboard_wrap  table {
          font-size: 10px;
      }
      .parnership_table, .extraa_table, .bowler_tbl, .batting_tbl tr:not(.show_con) {
          font-size: 10px;
      }
      .inning_tabs_wrap ul li a {
          font-size: 12px;
          border-bottom: 3px solid #444444;
          line-height: 38px;
      }
      .inning_tabs_wrap ul li.active a {
          border-bottom: 3px soli #00a622;
      }
      .scorboard_top_team {
          font-size: 12px;
      }
      .team_score {
          display: block;
      }
      .scorboard_top_team .score_card_team1 {
          width: 33.33333333%;
      }
      .scorboard_top_team .score_card_team_status {
          width: 33.33333333%;
      }
      .scorboard_top_team .score_card_team2 {
          width: 33.33333333%;
      }
      .score_card_team1 > span {
          display: inline-block;
          width: calc(100% - 20px);
          vertical-align: top;
      }
      .match_info_wrap ul li span {
          width: 85px;
          font-size: 12px;
          line-height: 24px;
          padding-right: 5px;
          padding-left: 5px;
      }
      .match_info_wrap ul li samp {
          width: calc(100% - 85px);
          width: -moz-calc(100% - 85px);
          width: -webkit-calc(100% - 85px);
          font-size: 11.5px;
          line-height: 24px;
      }
      .match_info_wrap ul li {
          margin-bottom: 0;
      }
      .wkt_hd.left {
          font-size: 11px;
      }
      .wkt_hd.right {
          font-size: 11px;
          padding-left: 8px;
      }
      .overs_commentry .over_content {
          font-size: 12px;
          padding-top: 5px;
          padding-bottom: 5px;
      }
      .wkt_hd.left span {
          margin-right: 1px;
      }
      .commentry_para_block {
          font-size: 11px;
      }
      .overs_commentry .over_heading {
          font-size: 12px;
          padding: 8px;
          padding-right: 32px;
          min-height: 33px;
      }
      .navigation ul li a {
          -ms-transform: scale(.7);
          -webkit-transform: scale(.7);
          transform: scale(.7);
      }
      .navigation ul li.nav_commentry a {
          margin-top: 6px;
      }
      .navigation ul li.nav_wicket a {
          margin-top: -4px;
      }
      .navigation ul li.nav_info a {
          margin-top: 5px;
      }

      .slogan_wrap span.slogan_left_area {
          display: inline-block;
          width: 100%;
      }
      .slogan_date {
          display:none;
          width: 100%;
          text-align: right;
          padding-right: 7px;
      }
      .showinmobile {
          display: inline;
      }
      .test .desktop_team_name {
          display: none;
      }
      .test .mobile_team_name {
          display: inline-block;
      }
      .test .both_team_block .mobile_team_name {
          display: inline-block;
          line-height: 16px;
          vertical-align: top;
      }
      .both_team.flex_div {
          width: 17%;
      }

      .extraa_table .left {
          width: 50%;
      }
      .extraa_table .right {
          width: 45%;
      }
      .extraa_table th {
          padding-left:3px;
          padding-right:3px;
          text-align: center;
      }

      .extraa_table th strong ,
      .extraa_table th span {
          display: block;
      }
      .inning_seprater_block {
          padding-left: 3px;
          padding-right: 3px;
      }
      .team_inn_block .innerteamblock {
          font-size: 12px;
      }
      .live_bat_bowl.left {
          width: 50%;
          padding-right: 10px;
      }
      .live_bat_bowl.right {
          width: 50%;
      }
      .recent_over_hd {
          display: block
      }
      .live_match_stat_wrap {
          padding-bottom: 5px;
      }

      .header_team_block {
          font-size: 11px;
      }
      .header_team_block h3 {
          font-size: 12px;
      }

      .header_center {
          font-size: 10px;
          text-align: center;
          padding: 0 7px;
      }
      .header_center span.sub_status {
          font-size: 9px;
      }
      .player_live_content_inner {
          font-size: 11px;
      }
      .footer_status_list {
          font-size: 12px;
      }
      .recent_over_list li span {
          font-size: 12px;
          min-width: 22px;
          height: 22px;
          line-height: 22px;
          padding: 0;
      }
      .recent_over_list li {
          margin-right: 6px;
          line-height: 21px;
      }
      .last_recent_overs ul {
          line-height: 10px;
      }
      .last_recent_overs ul li {
          padding-left: 5px;
          padding-right: 5px;
          line-height: 10px;
          font-size: 10px;
      }
      .last_recent_overs h4 {
          padding-right: 5px;
          font-size: 10px;
      }
      .live_footer_bot {
          padding: 7px 5px;
      }
      .live_footer_top{
          padding: 4px 5px;
      }
      .main_score_wrap{
          padding: 5px;
      }
      .player_live_content_inner {
          padding-left: 5px;
          padding-right: 5px;
      }
      .footer_status_list li span.footer_status_left {
          padding-right: 2px;
          font-size: 11px;
      }
      .footer_status_list li span.footer_status_right {
          color: #fff;
          font-size: 11px;
          font-weight: 700;
      }
      .wicket_popup {
          font-size: 11px;
          line-height: 14px;
          /*top: 5%;*/
      }
      .upcuming_text {
          font-size: 11px;
          font-weight: 300;
          line-height: 20px;
      }
      .white_content_block {
          padding: 7px 7px;
      }
      .player_profile_detail .left {
          padding-right: 8px;
          padding-left: 3px;
          width: 14%;
      }
      .player_profile_detail .left img {
          display: block;
          width: auto;
          max-width: 100%;
          display: block;
      }
      .player_profile_detail .right {
          width: 86%;
      }

      .wagon_wheel_right {
          width: calc(100% - 48px);
          margin-left: 48px;
      }

      .wagon_wheel_top_content  {
          padding: 0 5px 0 40px;
      }
      .wagon_wheel_bot_content {
          padding: 3px 0 3px 40px;
          /*background: transparent;*/
      }
      .wagon_wheel_top_content h3 {
          font-size: 13px;
          margin-bottom: 0;
          padding-top: 5px;
      }
      .inning_detail_ul  {
          font-size: 11px;
      }
      .inning_btn_ul  {
          padding: 2px 0 3px 0;
      }
      .inning_action_ul li {
          padding-right: 3px;
      }
      .inning_action_ul li span {
          line-height: 25px;
          min-width: 25px;
          font-size: 12px;
      }
      .animation_wrap.animated_action img {
          width: 140px;
      }
      .white_top_header{
          height: 30px;
      }
      .white_top_left {
          padding: 0px 7px;
          font-size: 12px;
          line-height: 30px;
          width: 40%;
      }
      .white_top_right {
          padding: 0px 10px;
          font-size: 12px;
          line-height: 30px;
          width: 60%;
      }
      .white_top_left:after {
          border-top: 25px solid transparent;
          border-left: 15px solid #439539
      }
      .table_repeat_area ul li .table_block span {
          width: 26px;
      }
      .table_repeat_area ul li .table_block.first span {
          width: 35px;
      }
      .list_content {
          font-size: 11px;
      }
      .table_repeat_area {
          padding-bottom: 3px;
          margin-bottom: 3px;
      }
      .table_repeat_area ul {
          font-size: 11px;
      }
      .table_repeat_area ul li .table_block.first {
          width:28%;
      }
      .table_repeat_area ul li .table_block.second {
          width:37%;
      }
      .table_repeat_area ul li .table_block.third {
          width:35%;
      }
      .list_content li {
          padding-bottom: 0;
      }


  }
  @media (max-width: 413px) {
      .current_over_wrap {
          margin-top: 5px;
          margin-bottom: 10px
      }
      .live_bat_bowl.left .player_name {
          width: calc(100% - 90px);
          min-width: inherit;
      }
      .table_repeat_area ul li .table_block span{
          font-size: 10px;
      }
      .table_repeat_area ul li .table_block strong{
          font-size: 10px;
      }
      .table_repeat_area ul li .table_block.third{
          width: 33.3%;
      }
      .table_repeat_area ul li .table_block.first{
          width: 30.3%;
      }
      .table_repeat_area ul li .table_block.second{
          width: 36.3%;
      }
  }

  /* REACT BALL TRACKING */
  .main_box{
  display:block;
  }
  .boxes_container{
  display:flex;
  flex-wrap: wrap;
  opacity:0.2;
  }
  .boxes_container .box{
    width: 8.33%;
    height: 25px;
    padding: 2px;
    box-sizing:border-box;
    visibility: hidden;
  }
  .boxes_container .box span{
    display: block;
    width: 100%;
    height: 100%;
  }
  .blue_box{
  background-color: blue;
  }
  .red_box{
  background-color: red;
  }
  body {
  font-family: sans-serif;
  }
  fieldset {
  position: relative;
  border-width: 1px;
  border-style: solid;
  margin-bottom: 10px;
  }
  legend {
  font-variant: small-caps;
  }
  #polygon-test {
  height: 160px;
  }
  #stepped-test {
  height: 200px;
  }
  #hover-test {
  height: 260px;
  }
  #delay-test {
  height: 150px;
  }
  #tree-test .block {
  position: relative;
  }
  .tree-test-wrap {
  display: flex;
  flex-Direction: row;
  align-items: flex-start;
  position: relative;
  }
  .tree-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  }
  .tree-block-wrap {
  padding: 0 100px 10px 0;
  }
  .tree-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  }
  .block {
  position: absolute;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: #000;
  cursor: pointer;
  text-align: center;
  color: #fff;
  }

  div {
  /*transition:all 5s*/
  }
  </style>
  <!--
  Notice the use of in the tags above.
  It will be replaced with the URL of the `public` folder during the build.
  Only files inside the `public` folder can be referenced from the HTML.

  Unlike "/favicon.ico" or "favicon.ico", "/favicon.ico" will
  work correctly both with client-side routing and a non-root public URL.
  Learn how to configure a non-root public URL by running `npm run build`.
  -->
  <title>React App</title>
  <style type="text/css">body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }

  code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  }
  </style><style type="text/css">
  .pitch{
  border: 2px solid black;
  width: 200px;
  height: 100px;
  margin-left: 158px;
  }
  .pitch1{
  border: 2px solid black;

  width: 219px;
  height: 51px;
  margin-left: 158px;
  }
  .pitch2{
  border: 2px solid black;
  width: 385px;
  height: 80px;
  margin-left: 75px;
  }
  .pitch3{
  border: 2px solid black;
  width: 470px;
  height: 44px;
  margin-left: 30px;
  }
  .pitch4{
  border: 2px solid black;
  width: 500px;
  height: 100px;
  margin-left: 0px;
  }
  .p-15{
  height: 100%;
  padding: 0px 15px;
  }
  .red{
  background: red;
  height: 100%;
  opacity:0.1;
  }
  .green{
  /*background: green;*/
  height: 100%;
  /*opacity:0.1;*/
  }
  .blue{
  background: blue;
  height: 100%;
  opacity:0.1;
  }
  .players-wrpa .App .main_box{
        width: 100%;
        background-repeat: no-repeat;
        height: 100%;
    }
  .p-0{
  padding:0px;
  }

  .pitch_class{
    border-image: linear-gradient( to left , rgba(155, 125, 103,1), rgba(179,150,118,0) ) 1 1 100%;
    /* border-image: linear-gradient(to right, rgba(179,150,118,0.48) 0%,rgba(179,150,118,1)1 1 100%); */
    transition: 1s;
    animation-name:lefToRight;
      animation-duration: 4s;
      animation-timing-function:linear;
    z-index: 2 !important;

  }
  .bats_class {
        border-radius: 10px;
        transition: 1s;
        animation-name:lefToRight;
          animation-duration: 4s;
          animation-timing-function:linear;
        animation-delay: 0.6s;
        animation-fill-mode:backwards;
        border-image: linear-gradient( to left , rgba(128, 123, 166,.7), rgba(179,150,118,1) ) 1 1 100%;
  }

  .App-logo {
  height: 40vmin;
  pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
  .App-logo {
  animation: App-logo-spin infinite 20s linear;
  }
  }

  .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  }

  .App-link {
  color: #61dafb;
  }

  @keyframes App-logo-spin {
  from {
  transform: rotate(0deg);
  }
  to {
  transform: rotate(360deg);
  }
  }

  @keyframes lefToRight {
  0% {max-width: 0;}
  100% {max-width: 100%;}
  }





  .bats_class:before{
    position: absolute;
    bottom: -2px;
    right: -2px;
    content: "";
    display: block;
    border-radius: 50%;
    width: 12px !important;
    height: 12px;
    background: red;
    animation-name:hideStart;
      animation-duration: 1.4s;
      animation-timing-function:linear;
    animation-fill-mode: backwards;
  }
  .pitch_class:before{
    position: absolute;
    bottom:0;
    right: 0;
    content: "";
    display: block;
    border-radius: 50%;
    width: 12px !important;
    height: 12px;
    background: red;
    animation-name:hideEnd;
      animation-duration: 1s;
      animation-timing-function:linear;
    margin: 0 -6px 0 0;
    animation-fill-mode: forwards;
  }
  @keyframes hideEnd {
    0%, 99%   {opacity: 1;}
    100% {
      opacity: .9;
      background: #6d6d6d;
    }
  }
  @keyframes hideStart {
    0%,20%   {opacity: 0;}
    100% {opacity: 1;}
  }
  .main_ground_img{
      /* width: 1000px; */
      width: 430px;
      margin: 0 auto;
  }
  .main_box {
    text-align: center;
    /* background-image:url('/component.png'); */
    height: 698px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    position:relative;
    /* padding: 20px; */

  }
  .boxes_container .box{
    height: 41px;
  }


 .wagon_wheel_left{
    display: flex;
    align-content: center;
    justify-content: center;
 }

  .wagon_wheel_left-score {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    background: #000;
    color: #fff;
    padding: 3px 10px;
    font-size: 12px;
    line-height: 16px;
  }
  .wagon_wheel_left-score .score_digit {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #00a826;
    flex-shrink: 0;
    color: #00a826;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 10px;
    background: #fff;
  }
  .wagon_wheel_left-score .score_name {
    flex-grow: 1;
  }

  .wagon_wheel_left-info {
    padding: 3px 10px;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-size: 12px;
    line-height: 14px;
  }
  .live_footer_left{
      flex-grow: 1;
  }
  .live_footer_right{
      flex-shrink: 0;
      margin-left: 10px;
  }
  .recent_over_list_api{
      flex-wrap: wrap;
  }
  .recent_over_list_api li{
      margin-top: 2px;
      margin-bottom: 2px;
  }

  @media (max-width: 991px) {
    .main_ground_img{
      /* width: 768px; */
    }
    .main_box{
      height: 536px;
    }
    .boxes_container .box{
      height: 31px;
      font-size: 10px;
    }
    /* .wagon .wagon_wheel_left{
      width: 364px;
      height: 364px;
    } */
  }
  @media (max-width: 767px) {
    /* .main_ground_img{
      width: 480px;
    } */
    .main_box{
      height: 335px;
    }
    .boxes_container .box{
      height: 20px;
      font-size: 10px;
    }
    /* .wagon .wagon_wheel_left{
      width: 220px;
      height: 220px;
    } */
  }
  @media (max-width: 480px) {
    .main_ground_img{
      max-width: 414px;
      width:auto;
    }
    .boxes_container .box{
      height: 18px;
      font-size: 10px;
    }
    .main_box{
      height: 299px;
    }

  }
  @media (max-width: 400px) {

    .boxes_container .box{
      height: 13px;
      font-size: 10px;
    }
    .main_box{
      height: 223px;
    }

  }

   /* ------------------- */
  /* REACT BALL TRACKING END  */
  /* REACT wagon_weel   */

  *,
  *::before,
  *::after {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
  }
  .round_circle_wrap {
      border-radius: 50%;
      width:180px;
      height: 180px;
      margin: 5px 0;
      position: relative;

      background-size: cover;
      background-image:url(https://www.sabcsport.com/assets/img/ground.webp);
      border:6px solid #fff;
      float: right;
  }
  .radio_block {
      position: absolute;
      left: 50%;
      top: 50%;
      display: block;
      border-radius: 100%;
      width: 10px;
      height: 10px;
      z-index: 1;
      margin: -5px;
      cursor: pointer;
      /*background: red;*/
  }
  .radio_block input {
      width: 100%;
      height: 100%;
      display: block;
      cursor: pointer;
  }
  .deg0 {transform: rotate(0deg) translate(89px);}
  .deg1 {transform: rotate(1deg) translate(89px);}
  .deg2 {transform: rotate(2deg) translate(89px);}
  .deg3 {transform: rotate(3deg) translate(89px);}
  .deg4 {transform: rotate(4deg) translate(89px);}
  .deg5 {transform: rotate(5deg) translate(89px);}
  .deg6 {transform: rotate(6deg) translate(89px);}
  .deg7 {transform: rotate(7deg) translate(89px);}
  .deg8 {transform: rotate(8deg) translate(89px);}
  .deg9 {transform: rotate(9deg) translate(89px);}
  .deg10 {transform: rotate(10deg) translate(89px);}
  .deg11 {transform: rotate(11deg) translate(89px);}
  .deg12 {transform: rotate(12deg) translate(89px);}
  .deg13 {transform: rotate(13deg) translate(89px);}
  .deg14 {transform: rotate(14deg) translate(89px);}
  .deg15 {transform: rotate(15deg) translate(89px);}
  .deg16 {transform: rotate(16deg) translate(89px);}
  .deg17 {transform: rotate(17deg) translate(89px);}
  .deg18 {transform: rotate(18deg) translate(89px);}
  .deg19 {transform: rotate(19deg) translate(89px);}
  .deg20 {transform: rotate(20deg) translate(89px);}
  .deg21 {transform: rotate(21deg) translate(89px);}
  .deg22 {transform: rotate(22deg) translate(89px);}
  .deg23 {transform: rotate(23deg) translate(89px);}
  .deg24 {transform: rotate(24deg) translate(89px);}
  .deg25 {transform: rotate(25deg) translate(89px);}
  .deg26 {transform: rotate(26deg) translate(89px);}
  .deg27 {transform: rotate(27deg) translate(89px);}
  .deg28 {transform: rotate(28deg) translate(89px);}
  .deg29 {transform: rotate(29deg) translate(89px);}
  .deg30 {transform: rotate(30deg) translate(89px);}
  .deg31 {transform: rotate(31deg) translate(89px);}
  .deg32 {transform: rotate(32deg) translate(89px);}
  .deg33 {transform: rotate(33deg) translate(89px);}
  .deg34 {transform: rotate(34deg) translate(89px);}
  .deg35 {transform: rotate(35deg) translate(89px);}
  .deg36 {transform: rotate(36deg) translate(89px);}
  .deg37 {transform: rotate(37deg) translate(89px);}
  .deg38 {transform: rotate(38deg) translate(89px);}
  .deg39 {transform: rotate(39deg) translate(89px);}
  .deg40 {transform: rotate(40deg) translate(89px);}
  .deg41 {transform: rotate(41deg) translate(89px);}
  .deg42 {transform: rotate(42deg) translate(89px);}
  .deg43 {transform: rotate(43deg) translate(89px);}
  .deg44 {transform: rotate(44deg) translate(89px);}
  .deg45 {transform: rotate(45deg) translate(89px);}
  .deg46 {transform: rotate(46deg) translate(89px);}
  .deg47 {transform: rotate(47deg) translate(89px);}
  .deg48 {transform: rotate(48deg) translate(89px);}
  .deg49 {transform: rotate(49deg) translate(89px);}
  .deg50 {transform: rotate(50deg) translate(89px);}
  .deg51 {transform: rotate(51deg) translate(89px);}
  .deg52 {transform: rotate(52deg) translate(89px);}
  .deg53 {transform: rotate(53deg) translate(89px);}
  .deg54 {transform: rotate(54deg) translate(89px);}
  .deg55 {transform: rotate(55deg) translate(89px);}
  .deg56 {transform: rotate(56deg) translate(89px);}
  .deg57 {transform: rotate(57deg) translate(89px);}
  .deg58 {transform: rotate(58deg) translate(89px);}
  .deg59 {transform: rotate(59deg) translate(89px);}
  .deg60 {transform: rotate(60deg) translate(89px);}
  .deg61 {transform: rotate(61deg) translate(89px);}
  .deg62 {transform: rotate(62deg) translate(89px);}
  .deg63 {transform: rotate(63deg) translate(89px);}
  .deg64 {transform: rotate(64deg) translate(89px);}
  .deg65 {transform: rotate(65deg) translate(89px);}
  .deg66 {transform: rotate(66deg) translate(89px);}
  .deg67 {transform: rotate(67deg) translate(89px);}
  .deg68 {transform: rotate(68deg) translate(89px);}
  .deg69 {transform: rotate(69deg) translate(89px);}
  .deg70 {transform: rotate(70deg) translate(89px);}
  .deg71 {transform: rotate(71deg) translate(89px);}
  .deg72 {transform: rotate(72deg) translate(89px);}
  .deg73 {transform: rotate(73deg) translate(89px);}
  .deg74 {transform: rotate(74deg) translate(89px);}
  .deg75 {transform: rotate(75deg) translate(89px);}
  .deg76 {transform: rotate(76deg) translate(89px);}
  .deg77 {transform: rotate(77deg) translate(89px);}
  .deg78 {transform: rotate(78deg) translate(89px);}
  .deg79 {transform: rotate(79deg) translate(89px);}
  .deg80 {transform: rotate(80deg) translate(89px);}
  .deg81 {transform: rotate(81deg) translate(89px);}
  .deg82 {transform: rotate(82deg) translate(89px);}
  .deg83 {transform: rotate(83deg) translate(89px);}
  .deg84 {transform: rotate(84deg) translate(89px);}
  .deg85 {transform: rotate(85deg) translate(89px);}
  .deg86 {transform: rotate(86deg) translate(89px);}
  .deg87 {transform: rotate(87deg) translate(89px);}
  .deg88 {transform: rotate(88deg) translate(89px);}
  .deg89 {transform: rotate(89deg) translate(89px);}
  .deg90 {transform: rotate(90deg) translate(89px);}
  .deg91 {transform: rotate(91deg) translate(89px);}
  .deg92 {transform: rotate(92deg) translate(89px);}
  .deg93 {transform: rotate(93deg) translate(89px);}
  .deg94 {transform: rotate(94deg) translate(89px);}
  .deg95 {transform: rotate(95deg) translate(89px);}
  .deg96 {transform: rotate(96deg) translate(89px);}
  .deg97 {transform: rotate(97deg) translate(89px);}
  .deg98 {transform: rotate(98deg) translate(89px);}
  .deg99 {transform: rotate(99deg) translate(89px);}
  .deg100 {transform: rotate(100deg) translate(89px);}
  .deg101 {transform: rotate(101deg) translate(89px);}
  .deg102 {transform: rotate(102deg) translate(89px);}
  .deg103 {transform: rotate(103deg) translate(89px);}
  .deg104 {transform: rotate(104deg) translate(89px);}
  .deg105 {transform: rotate(105deg) translate(89px);}
  .deg106 {transform: rotate(106deg) translate(89px);}
  .deg107 {transform: rotate(107deg) translate(89px);}
  .deg108 {transform: rotate(108deg) translate(89px);}
  .deg109 {transform: rotate(109deg) translate(89px);}
  .deg110 {transform: rotate(110deg) translate(89px);}
  .deg111 {transform: rotate(111deg) translate(89px);}
  .deg112 {transform: rotate(112deg) translate(89px);}
  .deg113 {transform: rotate(113deg) translate(89px);}
  .deg114 {transform: rotate(114deg) translate(89px);}
  .deg115 {transform: rotate(115deg) translate(89px);}
  .deg116 {transform: rotate(116deg) translate(89px);}
  .deg117 {transform: rotate(117deg) translate(89px);}
  .deg118 {transform: rotate(118deg) translate(89px);}
  .deg119 {transform: rotate(119deg) translate(89px);}
  .deg120 {transform: rotate(120deg) translate(89px);}
  .deg121 {transform: rotate(121deg) translate(89px);}
  .deg122 {transform: rotate(122deg) translate(89px);}
  .deg123 {transform: rotate(123deg) translate(89px);}
  .deg124 {transform: rotate(124deg) translate(89px);}
  .deg125 {transform: rotate(125deg) translate(89px);}
  .deg126 {transform: rotate(126deg) translate(89px);}
  .deg127 {transform: rotate(127deg) translate(89px);}
  .deg128 {transform: rotate(128deg) translate(89px);}
  .deg129 {transform: rotate(129deg) translate(89px);}
  .deg130 {transform: rotate(130deg) translate(89px);}
  .deg131 {transform: rotate(131deg) translate(89px);}
  .deg132 {transform: rotate(132deg) translate(89px);}
  .deg133 {transform: rotate(133deg) translate(89px);}
  .deg134 {transform: rotate(134deg) translate(89px);}
  .deg135 {transform: rotate(135deg) translate(89px);}
  .deg136 {transform: rotate(136deg) translate(89px);}
  .deg137 {transform: rotate(137deg) translate(89px);}
  .deg138 {transform: rotate(138deg) translate(89px);}
  .deg139 {transform: rotate(139deg) translate(89px);}
  .deg140 {transform: rotate(140deg) translate(89px);}
  .deg141 {transform: rotate(141deg) translate(89px);}
  .deg142 {transform: rotate(142deg) translate(89px);}
  .deg143 {transform: rotate(143deg) translate(89px);}
  .deg144 {transform: rotate(144deg) translate(89px);}
  .deg145 {transform: rotate(145deg) translate(89px);}
  .deg146 {transform: rotate(146deg) translate(89px);}
  .deg147 {transform: rotate(147deg) translate(89px);}
  .deg148 {transform: rotate(148deg) translate(89px);}
  .deg149 {transform: rotate(149deg) translate(89px);}
  .deg150 {transform: rotate(150deg) translate(89px);}
  .deg151 {transform: rotate(151deg) translate(89px);}
  .deg152 {transform: rotate(152deg) translate(89px);}
  .deg153 {transform: rotate(153deg) translate(89px);}
  .deg154 {transform: rotate(154deg) translate(89px);}
  .deg155 {transform: rotate(155deg) translate(89px);}
  .deg156 {transform: rotate(156deg) translate(89px);}
  .deg157 {transform: rotate(157deg) translate(89px);}
  .deg158 {transform: rotate(158deg) translate(89px);}
  .deg159 {transform: rotate(159deg) translate(89px);}
  .deg160 {transform: rotate(160deg) translate(89px);}
  .deg161 {transform: rotate(161deg) translate(89px);}
  .deg162 {transform: rotate(162deg) translate(89px);}
  .deg163 {transform: rotate(163deg) translate(89px);}
  .deg164 {transform: rotate(164deg) translate(89px);}
  .deg165 {transform: rotate(165deg) translate(89px);}
  .deg166 {transform: rotate(166deg) translate(89px);}
  .deg167 {transform: rotate(167deg) translate(89px);}
  .deg168 {transform: rotate(168deg) translate(89px);}
  .deg169 {transform: rotate(169deg) translate(89px);}
  .deg170 {transform: rotate(170deg) translate(89px);}
  .deg171 {transform: rotate(171deg) translate(89px);}
  .deg172 {transform: rotate(172deg) translate(89px);}
  .deg173 {transform: rotate(173deg) translate(89px);}
  .deg174 {transform: rotate(174deg) translate(89px);}
  .deg175 {transform: rotate(175deg) translate(89px);}
  .deg176 {transform: rotate(176deg) translate(89px);}
  .deg177 {transform: rotate(177deg) translate(89px);}
  .deg178 {transform: rotate(178deg) translate(89px);}
  .deg179 {transform: rotate(179deg) translate(89px);}
  .deg180 {transform: rotate(180deg) translate(89px);}
  .deg181 {transform: rotate(181deg) translate(89px);}
  .deg182 {transform: rotate(182deg) translate(89px);}
  .deg183 {transform: rotate(183deg) translate(89px);}
  .deg184 {transform: rotate(184deg) translate(89px);}
  .deg185 {transform: rotate(185deg) translate(89px);}
  .deg186 {transform: rotate(186deg) translate(89px);}
  .deg187 {transform: rotate(187deg) translate(89px);}
  .deg188 {transform: rotate(188deg) translate(89px);}
  .deg189 {transform: rotate(189deg) translate(89px);}
  .deg190 {transform: rotate(190deg) translate(89px);}
  .deg191 {transform: rotate(191deg) translate(89px);}
  .deg192 {transform: rotate(192deg) translate(89px);}
  .deg193 {transform: rotate(193deg) translate(89px);}
  .deg194 {transform: rotate(194deg) translate(89px);}
  .deg195 {transform: rotate(195deg) translate(89px);}
  .deg196 {transform: rotate(196deg) translate(89px);}
  .deg197 {transform: rotate(197deg) translate(89px);}
  .deg198 {transform: rotate(198deg) translate(89px);}
  .deg199 {transform: rotate(199deg) translate(89px);}
  .deg200 {transform: rotate(200deg) translate(89px);}
  .deg201 {transform: rotate(201deg) translate(89px);}
  .deg202 {transform: rotate(202deg) translate(89px);}
  .deg203 {transform: rotate(203deg) translate(89px);}
  .deg204 {transform: rotate(204deg) translate(89px);}
  .deg205 {transform: rotate(205deg) translate(89px);}
  .deg206 {transform: rotate(206deg) translate(89px);}
  .deg207 {transform: rotate(207deg) translate(89px);}
  .deg208 {transform: rotate(208deg) translate(89px);}
  .deg209 {transform: rotate(209deg) translate(89px);}
  .deg210 {transform: rotate(210deg) translate(89px);}
  .deg211 {transform: rotate(211deg) translate(89px);}
  .deg212 {transform: rotate(212deg) translate(89px);}
  .deg213 {transform: rotate(213deg) translate(89px);}
  .deg214 {transform: rotate(214deg) translate(89px);}
  .deg215 {transform: rotate(215deg) translate(89px);}
  .deg216 {transform: rotate(216deg) translate(89px);}
  .deg217 {transform: rotate(217deg) translate(89px);}
  .deg218 {transform: rotate(218deg) translate(89px);}
  .deg219 {transform: rotate(219deg) translate(89px);}
  .deg220 {transform: rotate(220deg) translate(89px);}
  .deg221 {transform: rotate(221deg) translate(89px);}
  .deg222 {transform: rotate(222deg) translate(89px);}
  .deg223 {transform: rotate(223deg) translate(89px);}
  .deg224 {transform: rotate(224deg) translate(89px);}
  .deg225 {transform: rotate(225deg) translate(89px);}
  .deg226 {transform: rotate(226deg) translate(89px);}
  .deg227 {transform: rotate(227deg) translate(89px);}
  .deg228 {transform: rotate(228deg) translate(89px);}
  .deg229 {transform: rotate(229deg) translate(89px);}
  .deg230 {transform: rotate(230deg) translate(89px);}
  .deg231 {transform: rotate(231deg) translate(89px);}
  .deg232 {transform: rotate(232deg) translate(89px);}
  .deg233 {transform: rotate(233deg) translate(89px);}
  .deg234 {transform: rotate(234deg) translate(89px);}
  .deg235 {transform: rotate(235deg) translate(89px);}
  .deg236 {transform: rotate(236deg) translate(89px);}
  .deg237 {transform: rotate(237deg) translate(89px);}
  .deg238 {transform: rotate(238deg) translate(89px);}
  .deg239 {transform: rotate(239deg) translate(89px);}
  .deg240 {transform: rotate(240deg) translate(89px);}
  .deg241 {transform: rotate(241deg) translate(89px);}
  .deg242 {transform: rotate(242deg) translate(89px);}
  .deg243 {transform: rotate(243deg) translate(89px);}
  .deg244 {transform: rotate(244deg) translate(89px);}
  .deg245 {transform: rotate(245deg) translate(89px);}
  .deg246 {transform: rotate(246deg) translate(89px);}
  .deg247 {transform: rotate(247deg) translate(89px);}
  .deg248 {transform: rotate(248deg) translate(89px);}
  .deg249 {transform: rotate(249deg) translate(89px);}
  .deg250 {transform: rotate(250deg) translate(89px);}
  .deg251 {transform: rotate(251deg) translate(89px);}
  .deg252 {transform: rotate(252deg) translate(89px);}
  .deg253 {transform: rotate(253deg) translate(89px);}
  .deg254 {transform: rotate(254deg) translate(89px);}
  .deg255 {transform: rotate(255deg) translate(89px);}
  .deg256 {transform: rotate(256deg) translate(89px);}
  .deg257 {transform: rotate(257deg) translate(89px);}
  .deg258 {transform: rotate(258deg) translate(89px);}
  .deg259 {transform: rotate(259deg) translate(89px);}
  .deg260 {transform: rotate(260deg) translate(89px);}
  .deg261 {transform: rotate(261deg) translate(89px);}
  .deg262 {transform: rotate(262deg) translate(89px);}
  .deg263 {transform: rotate(263deg) translate(89px);}
  .deg264 {transform: rotate(264deg) translate(89px);}
  .deg265 {transform: rotate(265deg) translate(89px);}
  .deg266 {transform: rotate(266deg) translate(89px);}
  .deg267 {transform: rotate(267deg) translate(89px);}
  .deg268 {transform: rotate(268deg) translate(89px);}
  .deg269 {transform: rotate(269deg) translate(89px);}
  .deg270 {transform: rotate(270deg) translate(89px);}
  .deg271 {transform: rotate(271deg) translate(89px);}
  .deg272 {transform: rotate(272deg) translate(89px);}
  .deg273 {transform: rotate(273deg) translate(89px);}
  .deg274 {transform: rotate(274deg) translate(89px);}
  .deg275 {transform: rotate(275deg) translate(89px);}
  .deg276 {transform: rotate(276deg) translate(89px);}
  .deg277 {transform: rotate(277deg) translate(89px);}
  .deg278 {transform: rotate(278deg) translate(89px);}
  .deg279 {transform: rotate(279deg) translate(89px);}
  .deg280 {transform: rotate(280deg) translate(89px);}
  .deg281 {transform: rotate(281deg) translate(89px);}
  .deg282 {transform: rotate(282deg) translate(89px);}
  .deg283 {transform: rotate(283deg) translate(89px);}
  .deg284 {transform: rotate(284deg) translate(89px);}
  .deg285 {transform: rotate(285deg) translate(89px);}
  .deg286 {transform: rotate(286deg) translate(89px);}
  .deg287 {transform: rotate(287deg) translate(89px);}
  .deg288 {transform: rotate(288deg) translate(89px);}
  .deg289 {transform: rotate(289deg) translate(89px);}
  .deg290 {transform: rotate(290deg) translate(89px);}
  .deg291 {transform: rotate(291deg) translate(89px);}
  .deg292 {transform: rotate(292deg) translate(89px);}
  .deg293 {transform: rotate(293deg) translate(89px);}
  .deg294 {transform: rotate(294deg) translate(89px);}
  .deg295 {transform: rotate(295deg) translate(89px);}
  .deg296 {transform: rotate(296deg) translate(89px);}
  .deg297 {transform: rotate(297deg) translate(89px);}
  .deg298 {transform: rotate(298deg) translate(89px);}
  .deg299 {transform: rotate(299deg) translate(89px);}
  .deg300 {transform: rotate(300deg) translate(89px);}
  .deg301 {transform: rotate(301deg) translate(89px);}
  .deg302 {transform: rotate(302deg) translate(89px);}
  .deg303 {transform: rotate(303deg) translate(89px);}
  .deg304 {transform: rotate(304deg) translate(89px);}
  .deg305 {transform: rotate(305deg) translate(89px);}
  .deg306 {transform: rotate(306deg) translate(89px);}
  .deg307 {transform: rotate(307deg) translate(89px);}
  .deg308 {transform: rotate(308deg) translate(89px);}
  .deg309 {transform: rotate(309deg) translate(89px);}
  .deg310 {transform: rotate(310deg) translate(89px);}
  .deg311 {transform: rotate(311deg) translate(89px);}
  .deg312 {transform: rotate(312deg) translate(89px);}
  .deg313 {transform: rotate(313deg) translate(89px);}
  .deg314 {transform: rotate(314deg) translate(89px);}
  .deg315 {transform: rotate(315deg) translate(89px);}
  .deg316 {transform: rotate(316deg) translate(89px);}
  .deg317 {transform: rotate(317deg) translate(89px);}
  .deg318 {transform: rotate(318deg) translate(89px);}
  .deg319 {transform: rotate(319deg) translate(89px);}
  .deg320 {transform: rotate(320deg) translate(89px);}
  .deg321 {transform: rotate(321deg) translate(89px);}
  .deg322 {transform: rotate(322deg) translate(89px);}
  .deg323 {transform: rotate(323deg) translate(89px);}
  .deg324 {transform: rotate(324deg) translate(89px);}
  .deg325 {transform: rotate(325deg) translate(89px);}
  .deg326 {transform: rotate(326deg) translate(89px);}
  .deg327 {transform: rotate(327deg) translate(89px);}
  .deg328 {transform: rotate(328deg) translate(89px);}
  .deg329 {transform: rotate(329deg) translate(89px);}
  .deg330 {transform: rotate(330deg) translate(89px);}
  .deg331 {transform: rotate(331deg) translate(89px);}
  .deg332 {transform: rotate(332deg) translate(89px);}
  .deg333 {transform: rotate(333deg) translate(89px);}
  .deg334 {transform: rotate(334deg) translate(89px);}
  .deg335 {transform: rotate(335deg) translate(89px);}
  .deg336 {transform: rotate(336deg) translate(89px);}
  .deg337 {transform: rotate(337deg) translate(89px);}
  .deg338 {transform: rotate(338deg) translate(89px);}
  .deg339 {transform: rotate(339deg) translate(89px);}
  .deg340 {transform: rotate(340deg) translate(89px);}
  .deg341 {transform: rotate(341deg) translate(89px);}
  .deg342 {transform: rotate(342deg) translate(89px);}
  .deg343 {transform: rotate(343deg) translate(89px);}
  .deg344 {transform: rotate(344deg) translate(89px);}
  .deg345 {transform: rotate(345deg) translate(89px);}
  .deg346 {transform: rotate(346deg) translate(89px);}
  .deg347 {transform: rotate(347deg) translate(89px);}
  .deg348 {transform: rotate(348deg) translate(89px);}
  .deg349 {transform: rotate(349deg) translate(89px);}
  .deg350 {transform: rotate(350deg) translate(89px);}
  .deg351 {transform: rotate(351deg) translate(89px);}
  .deg352 {transform: rotate(352deg) translate(89px);}
  .deg353 {transform: rotate(353deg) translate(89px);}
  .deg354 {transform: rotate(354deg) translate(89px);}
  .deg355 {transform: rotate(355deg) translate(89px);}
  .deg356 {transform: rotate(356deg) translate(89px);}
  .deg357 {transform: rotate(357deg) translate(89px);}
  .deg358 {transform: rotate(358deg) translate(89px);}
  .deg359 {transform: rotate(359deg) translate(89px);}
  .deg360 {transform: rotate(360deg) translate(89px);}
  .deg361 {transform: rotate(361deg) translate(89px);}



  /* REACT wagon_weel END  */

  .wagon_wheel_left_position{
      position: absolute;
      left: 0;
      top: -27px;
  }



  /*ball_track_css_2020_10_05*/




  .main_ground_img canvas{
      position: relative;
      z-index: 2;
  }



  .btn-remove01{
    position: relative;
    font-size: 12px;
    line-height: 12px;
    background: #fff;
    border-radius: 4px;
    display:flex;
    cursor: pointer;
    align-items: center;

    padding: 6px 20px 6px 12px;
    text-align: center;
    font-weight: 700;
    transition: all .25s linear;
  }
  .btn-remove01:hover{
    background: rgba(0,0,0,.1);
  }
  .btn-remove01:before{
    content: "X";
    position: absolute;
    right: 5px;
    top:50%;
    font-size: 10px;
    line-height: 10px;
    margin: -4px 0 0;
    color:gray;
  }
  @media (max-width: 767px){
    .btn-remove01{
      margin: 3px -6px 3px 0;
    }
  }
  .bottom-align{
    position: absolute;
      border-radius: 4px;
      -moz-border-radius: 4px;
      -webkit-border-radius: 4px;
      background: #ffffff;
      color: #333;
      top: auto !important;
      left: 50%;
      width: calc(100% - 30px) !important;
      font-weight: 500;
      overflow: hidden;
      z-index: 3;
      bottom: 40px !important;
      transform: translate(-50%, 0) !important;
      -moz-transform: translate(-50%, 0) !important;
      -webkit-transform: translate(-50%, 0) !important;
  }
.popup_headers .white_top_right{
  display: flex;
  justify-content: flex-end;
}

.score_wicket{
  color: black;
    margin-left: 15px;
}






/******widget css end's */
</style>
