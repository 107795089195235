import { render, staticRenderFns } from "./cricket-widget.vue?vue&type=template&id=4528d521&scoped=true&"
import script from "./cricket-widget.vue?vue&type=script&lang=js&"
export * from "./cricket-widget.vue?vue&type=script&lang=js&"
import style0 from "./cricket-widget.vue?vue&type=style&index=0&id=4528d521&prod&scoped=true&lang=css&"
import style1 from "./cricket-widget.vue?vue&type=style&index=1&id=4528d521&prod&lang=css&"
import style2 from "./cricket-widget.vue?vue&type=style&index=2&id=4528d521&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4528d521",
  null
  
)

/* custom blocks */
import block0 from "./cricket-widget.vue?vue&type=custom&index=0&blockType=title"
if (typeof block0 === 'function') block0(component)

export default component.exports